import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import ForgotPass from "./pages/auth/forgot-pass";
import Login from "./pages/auth/login";
import Logout from "./pages/auth/logout";
import ListPassengersPage from "./pages/organization/passengers/ListPassengersPage";
import SideMenuLayout from "./layouts/sidemenu/SideMenuLayout";
import UnauthenticatedLayout from "./layouts/noAuth/UnauthenticatedLayout";
import ListCrewPage from "./pages/organization/crew/ListCrewPage";
import ListAicraftPage from "./pages/organization/aircraft/ListAircraftPage";
import ListRoutesPage from "./pages/organization/routes/ListRoutesPage";
import ListFlightsPage from "./pages/organization/flights/ListFlighsPage";
import EditFlightPage, { flightLoader } from "./pages/organization/flights/EditFlightPage";
import PassengerDetailsPage, { passengerLoader } from "./pages/organization/passengers/PassengerDetailsPage";
import CrewDetailsPage, { crewLoader } from "./pages/organization/crew/CrewDetailsPage";
import AircraftDetailsPage, { aircraftLoader } from "./pages/organization/aircraft/AircraftDetailsPage";
import ImportRoutePage, { importRouteLoader } from "./pages/organization/routes/ImportRoutePage";
import FlightFplPage from "./pages/organization/flights/FlightFplPage";
import AutorouterAuthPage from "./pages/auth/AutorouterAuthPage";
import FlightWbPage from "./pages/organization/flights/FlightWbPage";
import RouteDetailsPage, { routeLoader } from "./pages/organization/routes/RouteDetailsPage";
import FlightBoardingPassesPage, { FlightBoardingPassesErrorPage, flightBoardingPassesLoader } from "./pages/organization/flights/FlightBoardingPassesPage";
import FlyScanBoardingPassPage from "./pages/fly/FlyScanBoardingPassPage";
import FlyFlightDetailsPage from "./pages/fly/FlyFlightDetailsPage";
import FlyListFlightsPage from "./pages/fly/FlyListFlightsPage";
import FlyLayout from "./layouts/fly/FlyLayout";
import RouteMapPage from "./pages/organization/routes/RouteMapPage";
import UserInviteDetailsPage, { userInviteLoader } from "./pages/invites/UserInviteDetailsPage";
import Register from "./pages/auth/register";
import FlightEmbarkationStatusPage from "./pages/organization/flights/FlightEmbarkationStatusPage";
import ListFlightPlansPage from "./pages/organization/flightPlans/ListFlightPlansPage";
import FlightPlanDetailsPage, { flightPlanLoader } from "./pages/organization/flightPlans/FlightPlanDetailsPage";
import QuickAddFlightPlanPage from "./pages/organization/flightPlans/QuickAddFlightPlanPage";
import FlightPlanFplPage from "./pages/organization/flightPlans/FlightPlanFplPage";
import FlightDocFillPage from "./pages/organization/flights/FlightDocFillPage";
import FlightDocSignPage from "./pages/organization/flights/FlightDocSignPage";
import RequestInfoCrewPage, { requestInfoCrewLoader } from "./pages/requestinfo/RequestInfoCrewPage";
import ShortlinkExpanderPage, { shortlinkExpanderLoader } from "./pages/go/ShortlinkExpanderPage";
import RequestInfoPassengerPage, { requestInfoPassengerLoader } from "./pages/requestinfo/RequestInfoPassengerPage";
import RequestInfoLayout from "./layouts/noAuth/RequestInfoLayout";
import ToolsFreeLayout from "./layouts/tools/ToolsFreeLayout";
import FlightDocsToolsPage from "./pages/tools/FlightDocsToolsPage";
import Static404Page from "./pages/static/Static404Page";
import AccountMainPage from "./pages/account/AccountMainPage";
import AccountPurchaseCreditsSuccessPage from "./pages/account/purchaseCredits/AccountPurchaseCreditsSuccessPage";
import AccountPurchaseCreditsCancelPage from "./pages/account/purchaseCredits/AccountPurchaseCreditsCancelPage";
import FlyFlightWatchPage from "./pages/fly/FlyFlightWatchPage";
import AddAircraftPage from "./pages/organization/aircraft/AddAircraftPage";
import CreateOrganizationPage from "./pages/organization/selectOrganization/CreateOrganizationPage";
import AirfieldSlotsSelectPage, { airfieldSlotTimesLoader } from "./pages/airfield/AirfieldSlotsSelectPage";
import FlightActionsSchedulePage from "./pages/organization/flights/FlightActionsSchedulePage";

export const router = createBrowserRouter([
  {
    element: <App />,
    children: [
      {
        element: <UnauthenticatedLayout />,
        children: [
          {
            path: "/auth/login",
            element: <Login />,
          },
          {
            path: "/auth/register",
            element: <Register />,
          },
          {
            path: "/auth/forgot-pass",
            element: <ForgotPass />,
          }
        ],
      },
      {
        path: "/auth/logout",
        element: <Logout />,
      },
      {
        path: "/routes/map",
        element: <RouteMapPage />,
      },
      {
        path: "/organization/create",
        element: <CreateOrganizationPage />,
      },
      {
        element: <SideMenuLayout />,
        children: [
          {
            element: <AirfieldSlotsSelectPage />,
            path: "/airfield/:airfieldId/slots",
            loader: airfieldSlotTimesLoader
          },
          {
            path: "/autorouter/:callback?",
            element: <AutorouterAuthPage />,
          },
          {
            path: "/account",
            children: [
              {
                 path: "/account", 
                 element: <AccountMainPage /> 
                },
                {
                  path:"/account/purchase_credits",
                  children: [
                    {
                      path: "/account/purchase_credits/success",
                      element: <AccountPurchaseCreditsSuccessPage />,
                    },
                    {
                      path: "/account/purchase_credits/cancel",
                      element: <AccountPurchaseCreditsCancelPage />,
                    },
                  ]
                }
            ],
          },
          {
            path: "/flights?",
            element: <ListFlightsPage />,
            children: [
              {
                path: "/flights/:flightId",
                element: <EditFlightPage />,
                loader: flightLoader,
                children: [
                  {
                    path: "/flights/:flightId/wb",
                    element: <FlightWbPage />,
                    loader: flightLoader
                  },
                ]
              },
              {
                path: "/flights/:flightId/fpl",
                element: <FlightFplPage />,
                loader: flightLoader
              },
              {
                path: "/flights/:flightId/schedule",
                element: <FlightActionsSchedulePage />,
                loader: flightLoader
              },
              {
                path: "/flights/:flightId/boardingPasses",
                element: <FlightBoardingPassesPage />,
                loader: flightBoardingPassesLoader,
                errorElement: <FlightBoardingPassesErrorPage />
              },
              {
                path: "/flights/:flightId/embarkationStatus",
                element: <FlightEmbarkationStatusPage />,
                loader: flightLoader
              },
              {
                path: "/flights/add",
                element: <EditFlightPage />
              },
              
            ]
          },
          
          {
            path: "/fpls",
            element: <ListFlightPlansPage />,
            children: [
              {
                path: "/fpls/:fplId",
                element: <FlightPlanDetailsPage />,
                loader: flightPlanLoader
              },
              {
                path: "/fpls/add",
                element: <FlightPlanDetailsPage />
              },
              {
                path: "/fpls/quickadd",
                element: <QuickAddFlightPlanPage />
              },
              {
                path: "/fpls/:fplId/fpl",
                element: <FlightPlanFplPage />,
                loader: flightPlanLoader
              },
            ]
          },
          
          {
            path: "/aircraft",
            element: <ListAicraftPage />,
            children: [
              {
                path: "/aircraft/add",
                element: <AddAircraftPage />
              },
              {
                path: "/aircraft/:aircraftId",
                element: <AircraftDetailsPage />,
                loader: aircraftLoader
              },
            ]
          },

          {
            path: "/crew",
            element: <ListCrewPage />,
            children: [
              {
                path: "/crew/:crewId",
                element: <CrewDetailsPage />,
                loader: crewLoader
              },
              {
                path: "/crew/add",
                element: <CrewDetailsPage />
              }
            ]
          },
          {
            path: "/passengers",
            element: <ListPassengersPage />,
            children: [
              {
                path: "/passengers/:passengerId",
                element: <PassengerDetailsPage />,
                loader: passengerLoader
              },
              {
                path: "/passengers/add",
                element: <PassengerDetailsPage />
              },

            ],
          },

          {
            path: "/routes",
            element: <ListRoutesPage />,
            children: [
              {
                path: "/routes/:routeId",
                element: <RouteDetailsPage />,
                loader: routeLoader
              },

              {
                path: "/routes/import/:importedRouteId",
                element: <ImportRoutePage />,
                loader: importRouteLoader
              }
            ]
          }
        ],
      },
      {
        element: <FlyLayout />,
        children: [
          {
            path: "/fly",
            element: <FlyListFlightsPage />,
          },
          {
            path: "/fly/:flightId/scan",
            element: <FlyScanBoardingPassPage />,
            loader: flightLoader,
          },
          {
            path: "/fly/:flightId",
            element: <FlyFlightDetailsPage />,
            loader: flightLoader,
          },
          {
            path: "/fly/:flightId/watch",
            element: <FlyFlightWatchPage />,
            loader: flightLoader,
          },
          {
            path: "/fly/:flightId/docs/:docType",
            element: <FlightDocFillPage />
          },
          {
            path: "/fly/:flightId/docs/:docType/edit",
            element: <FlightDocFillPage />
          },
          {
            path: "/fly/:flightId/docs/:docType/sign",
            element: <FlightDocSignPage />
          },
          {
            element: <AirfieldSlotsSelectPage />,
            path: "/fly/airfield/:airfieldId/slots",
            loader: airfieldSlotTimesLoader
          },
        ]
      },
      {
        element: <ToolsFreeLayout />,
        children: [
          {
            path: "/tools/flightdocs",
            element: <FlightDocsToolsPage />
          }
        ]

      },
      {
        path: "/invites/:inviteId",
        element: <UserInviteDetailsPage />,
        loader: userInviteLoader

      },
      {
        path:"/go/:token",
        element: <ShortlinkExpanderPage />,
        loader: shortlinkExpanderLoader
      },
      {
        element: <RequestInfoLayout />,
        children: [
          {
            path:"/requestinfo/crew",
            element: <RequestInfoCrewPage />,
            loader: requestInfoCrewLoader
          },
          {
            path:"/requestinfo/passenger",
            element: <RequestInfoPassengerPage />,
            loader: requestInfoPassengerLoader
          }
        ]
      },
      {
        element: <Static404Page/>,
        path: "*"
      }
    ],
  },
]);
  