import { LoaderFunction, useLoaderData, useNavigate } from "react-router";
import { AviTracerApi } from "../../../avitracerApi";
import { EntityDisplayInfo } from "../../../components/EntityDetails/EntityDetailsDisplayInfo";
import EntityDetailsPage from "../../../components/EntityDetails/EntityDetailsPage"
import Flight from "../../../models/flight"
import { useAppDispatch } from "../../../store/hooks";
import { createFlight, deleteFlight, editFlight } from "../../../store/flightSlice";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import { AirfieldSlotType } from "../../../models/airfieldSlots";
import { database } from "../../../firebase";
import { ref, set } from "firebase/database";
import { useCallback, useMemo } from "react";

export const flightLoader: LoaderFunction = async (params) => {
  if (params.params["flightId"]) {
      return AviTracerApi.getFlightWithId(params.params["flightId"]!);
  }

  return null;
};

function EditFlightPage(){
  const dispatch = useAppDispatch()
  const navigate = useNavigate();
  
  const [searchParams] = useSearchParams();
  
  const searchParamDepartureDateTime = useMemo(() => {
    return searchParams.has("departureDateTime") ? moment((Number(searchParams.get("departureDateTime")!)) * 1000) : undefined;
  },[searchParams])
 
  const airfieldSlots: Flight["airfieldSlots"] = useMemo(() => {
    if (searchParams.has("depAirfieldId") && searchParams.has("departureDateTime") && searchParams.has("slotType") && searchParams.has("bookedSlotKey")) {
      return {
        departure: {
          airfieldId: searchParams.get("depAirfieldId")!,
          startDateTimeEpoch: Number(searchParams.get("departureDateTime")!),
          slotType: searchParams.get("slotType")! as AirfieldSlotType,
          bookedSlotKey: searchParams.get("bookedSlotKey")!,    
        },
      }
    }
    return undefined;
  },[searchParams]);

  const loadedFlight = useLoaderData() as Flight;

  const flight: Flight = loadedFlight ?? {
    departureDateTime: searchParamDepartureDateTime,
    airfieldSlots,
  };

  const deletePendingSlotIfExists = useCallback(() => {
    if (searchParamDepartureDateTime && searchParams.has("depAirfieldId") && searchParams.has("pendingSlotKey")){
      const slotDatePath = `/airfield/${searchParams.get("depAirfieldId")}/slots/${moment(searchParamDepartureDateTime).format("DD-MM-YYYY")}`
      const refToDelete = ref(database, `${slotDatePath}/${searchParams.get("pendingSlotKey")}`)
      set(refToDelete, null)
    }
  }, [searchParams, searchParamDepartureDateTime])

  const handleSaveFlight = async (flight: Flight) => {
    if (!flight.id) {
      return await dispatch(createFlight(flight)).unwrap()
    }else{
      return await dispatch(editFlight(flight)).unwrap()
    }
  };

  const handleDeleteFlight = async (flight: Flight) => {
    await dispatch(deleteFlight(flight)).unwrap()
  };

  const goBack = () => {
    deletePendingSlotIfExists()

    navigate("/flights")
  }

  return (
    <EntityDetailsPage
      entity={flight}
      handleClose={goBack}
      displayInfo={EntityDisplayInfo.Flight()}
      dispatchSaveEntity={handleSaveFlight}
      dispatchDeleteEntity={handleDeleteFlight}
    />
  );
}

export default EditFlightPage