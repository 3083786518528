import { getAircraftDisplayInfo } from "../../models/aircraft"
import { getCrewDisplayInfo } from "../../models/crew"
import Flight, { getFlightDisplayInfo } from "../../models/flight"
import { Passenger, getPassengerDisplayInfo } from "../../models/passenger"
import { getInvitedUserDataDisplayInfo } from "../../models/userInvite";
import flatpickr from "flatpickr";
import { getImportedRouteDisplayInfo, getRouteDisplayInfo } from "../../models/route";
import { FieldValues, Message, Path, RegisterOptions, ValidationRule } from "react-hook-form";
import { getFlightPlanDisplayInfo } from "../../models/flightPlan";
import { getToolsFlightDisplayInfo } from "../../models/toolsFlight";

export type EntityDetailsDisplayInfo<Entity extends FieldValues> = {
    entityName: "Flight" | "Aircraft" | "Crew" | "Flight Plan" | "Quick Flight Plan" | "ImportedRoute" | "Passenger" | "Route" | "UserInvite" | "ToolsFlight",
    hardcodedTitle?: string,
    nextButtonTitle?: string,
    entityCanBeDeleted?: boolean,
    sections: EntityDetailsDisplayInfoSection<Entity>[]
}

export type EntityDetailsDisplayInfoSection<Entity extends FieldValues> = {
    sectionName: string,
    sectionId: string,
    inputs: EntityDetailsDisplayInfoField<Entity>[]
    shouldDisplay?: (entity: Entity) => boolean
    containsSharedValues?: boolean //if true, the section can be editted only if current user has org permissions on the entity
}


export type EntityDetailsDisplayInfoField<Entity extends FieldValues> =
    EntityDetailsDisplayInfoFieldText<Entity>
    | EntityDetailsDisplayInfoFieldNumber<Entity>
    | EntityDetailsDisplayInfoFieldTimeWithAutoSet<Entity>
    | EntityDetailsDisplayInfoFieldBoolean<Entity>
    | EntityDetailsDisplayInfoFieldDate<Entity>
    | EntityDetailsDisplayInfoFieldDateTime<Entity>
    | EntityDetailsDisplayInfoFieldIntermediateStop<Entity>
    | EntityDetailsDisplayInfoFieldAircraft<Entity>
    | EntityDetailsDisplayInfoFieldAircraftWbProfile<Entity>
    | EntityDetailsDisplayInfoFieldAircraftSar<Entity>
    | EntityDetailsDisplayInfoFieldRoute<Entity>
    | EntityDetailsDisplayInfoFieldPassengersLeg<Entity>
    | EntityDetailsDisplayInfoFieldCrew<Entity>
    | EntityDetailsDisplayInfoFieldDropdown<Entity>
    | EntityDetailsDisplayInfoFieldDropdownWithInput<Entity>
    | EntityDetailsDisplayInfoFieldPassengers<Entity>
    | EntityDetailsDisplayInfoFieldAftnAddresses<Entity>
    | EntityDetailsDisplayInfoFieldFuelOnBoard<Entity>
    | EntityDetailsDisplayInfoFieldFlightId<Entity>
    | EntityDetailsDisplayInfoFieldWb<Entity>
    | EntityDetailsDisplayInfoFieldPersonOnBoard<Entity>
    | EntityDetailsDisplayInfoFieldFlightPlanPoint<Entity>
    | EntityDetailsDisplayInfoFieldCustomRoute<Entity>
    | EntityDetailsDisplayInfoFieldSignature<Entity>
    | EntityDetailsDisplayInfoFieldAirfieldSlot<Entity>

export interface EntityDetailsDisplayInfoBase<Entity extends FieldValues> {
    name: string,
    key: Path<Entity>,
    disabled?: boolean,
    parentEntity?: string,
    required?: Message | ValidationRule<boolean>,
    validateRules?: RegisterOptions<Entity, Path<Entity>>;
}

export function getValitationRules<Entity extends FieldValues>(field: EntityDetailsDisplayInfoField<Entity>): RegisterOptions<Entity, Path<Entity>> {

    var requiredRule = field.required
    if (field.required as string) {
        requiredRule = "Required"
    }

    return {
        required: requiredRule,
        ...field.validateRules
    }
}

export type EntityDetailsDisplayInfoFieldText<Entity extends FieldValues> = {
    type: "text"
    defaultValue?: string
    uppercase?: boolean
    multiline?: boolean
} & EntityDetailsDisplayInfoBase<Entity>

export type EntityDetailsDisplayInfoFieldNumber<Entity extends FieldValues> = {
    type: "number"
    defaultValue?: number
} & EntityDetailsDisplayInfoBase<Entity>

export type EntityDetailsDisplayInfoFieldTimeWithAutoSet<Entity extends FieldValues>= {
    type: "time-autoset"
    getCalculatedValue: (entity: Entity) => number
} & EntityDetailsDisplayInfoBase<Entity>

export type EntityDetailsDisplayInfoFieldBoolean<Entity extends FieldValues> = {
    type: "boolean"
} & EntityDetailsDisplayInfoBase<Entity>

export type EntityDetailsDisplayInfoFieldDate<Entity extends FieldValues>  = {
    type: "date"
    defaultValue?: Date
    flatpickrOptions?: Omit<flatpickr.Options.Options, "defaultDate">
    useUtc?: boolean
} & EntityDetailsDisplayInfoBase<Entity>

export type EntityDetailsDisplayInfoFieldDateTime<Entity extends FieldValues>  = {
    type: "dateTime"
    defaultValue?: Date
    suggestedValue?: Date
    dateFlatpickrOptions?: Omit<flatpickr.Options.Options, "defaultDate" | "enableTime" | "noCalendar">
    timeFlatpickrOptions?: Omit<flatpickr.Options.Options, "defaultDate" | "enableTime" | "noCalendar">
    timeName: string
    useUtc?: boolean
    showOnlyTimePicker?: boolean
} & EntityDetailsDisplayInfoBase<Entity>

export type EntityDetailsDisplayInfoFieldIntermediateStop<Entity extends FieldValues> = {
    type: "intermediateStop"
    dataKey: Path<Entity>
} & EntityDetailsDisplayInfoBase<Entity>

export type EntityDetailsDisplayInfoFieldAircraft<Entity extends FieldValues> = {
    type: "aircraft"
} & EntityDetailsDisplayInfoBase<Entity>

export type EntityDetailsDisplayInfoFieldAircraftWbProfile<Entity extends FieldValues> = {
    type: "aircraftWbProfileName"
} & EntityDetailsDisplayInfoBase<Entity>

export type EntityDetailsDisplayInfoFieldRoute<Entity extends FieldValues> = {
    type: "route"
    customRouteKey?: Path<Flight>
} & EntityDetailsDisplayInfoBase<Entity>

export type EntityDetailsDisplayInfoFieldPassengersLeg<Entity extends FieldValues> = {
    type: "passengersLeg"
} & EntityDetailsDisplayInfoBase<Entity>

export type EntityDetailsDisplayInfoFieldCrew<Entity extends FieldValues> = {
    type: "crew"
} & EntityDetailsDisplayInfoBase<Entity>

export type EntityDetailsDisplayInfoFieldDropdown<Entity extends FieldValues> = {
    type: "dropdown"
    options: {value: string, label: string}[]
} & EntityDetailsDisplayInfoBase<Entity>

export type EntityDetailsDisplayInfoFieldDropdownWithInput<Entity extends FieldValues> = {
    type: "dropdownInput"
    options: {value: string, label: string, hardcodedInputValue?: string}[]
    required? : boolean
} & Omit<EntityDetailsDisplayInfoBase<Entity>, "required"| "validate" >

export type EntityDetailsDisplayInfoFieldPassengers<Entity extends FieldValues> = {
    type: "passengers"
    requiredPassengerFields: Path<Passenger>[]
    showSignupModalForExistingPassengers?: boolean
} & EntityDetailsDisplayInfoBase<Entity>

export type EntityDetailsDisplayInfoFieldAircraftSar<Entity extends FieldValues> = {
    type: "aircraftSar"
} & EntityDetailsDisplayInfoBase<Entity>

export type EntityDetailsDisplayInfoFieldAftnAddresses<Entity extends FieldValues> = {
    type: "aftnAddresses"
} & EntityDetailsDisplayInfoBase<Entity>

export type EntityDetailsDisplayInfoFieldWb<Entity extends FieldValues> = {
    type: "wb"
    key: "wb"
} & Omit<EntityDetailsDisplayInfoBase<Entity>, "key">

export type EntityDetailsDisplayInfoFieldFlightId<Entity extends FieldValues> = {
    type: "flightId"
    flightIdTypeKey: Path<Entity>
} & EntityDetailsDisplayInfoBase<Entity>

export type EntityDetailsDisplayInfoFieldFuelOnBoard<Entity extends FieldValues> = {
    type: "fuelOnBoard"
} & EntityDetailsDisplayInfoBase<Entity>

export type EntityDetailsDisplayInfoFieldPersonOnBoard<Entity extends FieldValues> = {
    type: "pob"
} & EntityDetailsDisplayInfoBase<Entity>

export type EntityDetailsDisplayInfoFieldFlightPlanPoint<Entity extends FieldValues> = {
    type: "flightPlanPoint"
} & EntityDetailsDisplayInfoBase<Entity>

export type EntityDetailsDisplayInfoFieldCustomRoute<Entity extends FieldValues> = {
    type: "customRoute"
    intermediateStopsKey?: Path<Flight>,
} & EntityDetailsDisplayInfoBase<Entity>

export type EntityDetailsDisplayInfoFieldSignature<Entity extends FieldValues> = {
    type: "signature"
    entityType: "crew" | "passenger"
} & EntityDetailsDisplayInfoBase<Entity>

export type EntityDetailsDisplayInfoFieldAirfieldSlot<Entity extends FieldValues> = {    
    type: "airfieldSlot"
} & EntityDetailsDisplayInfoBase<Entity>

export const EntityDisplayInfo = {
    Passenger: getPassengerDisplayInfo,
    Crew: getCrewDisplayInfo,
    InvitedUserData: getInvitedUserDataDisplayInfo,
    Aircraft: getAircraftDisplayInfo,
    Flight: getFlightDisplayInfo,
    FlightPlan: getFlightPlanDisplayInfo,
    ImportedRoute: getImportedRouteDisplayInfo,
    Route: getRouteDisplayInfo,
    ToolsFlight: getToolsFlightDisplayInfo
}
