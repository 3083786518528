import { Cell, CellContext, createColumnHelper, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import moment from "moment";
import {  Alert, Button, Col, Container, Modal, Row, Table } from "react-bootstrap";
import ReadOnlyInputBox from "../../components/InputBox/ReadonlyInputBox";
import { useCallback, useEffect, useMemo, useState } from "react";
import TextAreaBox from "../../components/InputBox/TextAreaBox";
import InputBox from "../../components/InputBox/InputBox";
import { DatabaseReference, OnDisconnect, onDisconnect, onValue, push, ref, set } from "firebase/database";
import { auth, database } from "../../firebase";
import { LoaderFunction, useLoaderData, useMatch, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { AviTracerApi } from "../../avitracerApi";
import { useAppSelector } from "../../store/hooks";
import { selectedOrganization } from "../../store/organizationsSlice";
import { AirfieldSlot, AirfieldSlotStored, AirfieldSlotTime, AirfieldSlotType, AirfieldSlotsInfo } from "../../models/airfieldSlots";
import FlatPicker from 'react-flatpickr'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendarDays,faTrash } from '@fortawesome/free-solid-svg-icons'
import ListEntitiesHeaderComponent from "../../components/ListEntities/ListEntitiesHeaderComponent";
import LoadingComponent from "../../components/LoadingComponent";
import useCurrentTime from "../../utils/useCurrentTime";
import { Organization } from "../../models/organization";
import { useMediaQuery } from "usehooks-ts";

export const airfieldSlotTimesLoader: LoaderFunction = async (params) => {
  const airfieldId = params.params["airfieldId"]!.toLowerCase();

  const url = new URL(params.request.url);
  var dateStr: string = moment().startOf("day").utc(true).format("DD-MM-YYYY");
  if (url.searchParams.has("date")) {
    dateStr = url.searchParams.get("date")!
  }

  return await AviTracerApi.getAirfieldSlotTimesInfoForDate(airfieldId, dateStr);
}


function AirfieldSlotsSelectPage() {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentTime = useCurrentTime();

  const selectedOrg = useAppSelector(selectedOrganization);

  const { slotTimes: loadedSlotTimes, restrictions: slotRestrictions, adminOrgId, slotsNeedApproval, timeTypesToLog } = useLoaderData() as AirfieldSlotsInfo;

  const airfieldId = params["airfieldId"]!.toLowerCase();
  const dateStr = searchParams.has("date") ? searchParams.get("date") : moment().utc(true).format("DD-MM-YYYY");
  const date = useMemo(() => {
    return moment(dateStr, "DD-MM-YYYY").utc(true).toDate();
  }, [dateStr]);

  const isAdminOrg = adminOrgId === selectedOrg?.id

  const slotDatePath = useMemo(() => {
    return `/airfield/${airfieldId}/slots/${dateStr}`;
  }, [dateStr, airfieldId]);

  const [storedSlots, setStoredSlots] = useState<AirfieldSlotStored[]>([]);
  const [hasLoadedStoredSlots, setHasLoadedStoredSlots] = useState(false);

  useEffect(() => {
    if (!selectedOrg?.airfieldSlots.some((s) => s.airfieldId === airfieldId)) {
      throw new Error("Organization does not have access to this airfield");
    }
    setHasLoadedStoredSlots(false);
    const query = ref(database, slotDatePath);
    return onValue(
      query,
      (snapshot) => {
        //add loading
        if (snapshot.exists()) {
          const data: AirfieldSlotStored[] = Object.entries(snapshot.val()).map(([key, value]) => {
            (value as AirfieldSlotStored).id = key;
            return value as AirfieldSlotStored;
          });

          setStoredSlots(data);
        } else {
          setStoredSlots([]);
        }
        setHasLoadedStoredSlots(true);
      },
      (error) => {
        console.error(error);
      }
    );
  }, [airfieldId, date, selectedOrg?.airfieldSlots, slotDatePath]);

  const [slotTimes, setSlotTimes] = useState<AirfieldSlotTime[]>([]);

  useEffect(() => {
    if (!hasLoadedStoredSlots) {
      return;
    }
    var tempSlotTimes: AirfieldSlotTime[] = JSON.parse(JSON.stringify(loadedSlotTimes)); //create deep copy
    tempSlotTimes.forEach((st) => {
      //set again startDateTime that is lost at JSON.parse
      st.startDateTime = moment(st.startDateTimeEpoch * 1000).utc(false).toDate();
    });

    storedSlots.forEach((storedSlot) => {
      if (storedSlot.status === "unavailable") {
        const affectedSlotTimes = tempSlotTimes.filter(
          (s) =>
            s.startDateTime.getTime() >= storedSlot.startDateTimeEpoch * 1000 &&
            s.startDateTime.getTime() <= storedSlot.endDateTimeEpoch * 1000
        );
        affectedSlotTimes.forEach((s) => {
          (Object.values(s.slots) as AirfieldSlot[]).forEach((slot) => {
            slot.available = false;
            slot.unavailableReason = storedSlot.reason;
            slot.unavailableSlotId = storedSlot.id;
          });
        });
      } else {
        const slotTime = tempSlotTimes.find(
          (tempSlotTime) => tempSlotTime.startDateTime.getTime() === storedSlot.startDateTimeEpoch * 1000
        );
        if (slotTime) {
          if (storedSlot.status === "booked") {
            slotTime.slots[storedSlot.slotType].bookedBy = {
              uid: storedSlot.bookedBy.uid,
              orgId: storedSlot.bookedBy.orgId,
              orgName: storedSlot.bookedBy.orgName,
              callsign: storedSlot.bookedBy.callsign,
              flightId: storedSlot.bookedBy.flightId,
              bookedSlotId: storedSlot.id,
            };
            slotTime.slots[storedSlot.slotType].blocksOffEpoch = storedSlot.blocksOffEpoch;
            slotTime.slots[storedSlot.slotType].takeoffEpoch = storedSlot.takeoffEpoch;
            slotTime.slots[storedSlot.slotType].landedEpoch = storedSlot.landedEpoch;
          }else if (storedSlot.status === "requested" && storedSlot.requestedBy.orgId === selectedOrg?.id! && !isAdminOrg){
              slotTime.slots[storedSlot.slotType].selectedOrgRequest = {
                requestedSlotId: storedSlot.id,
                callsign: storedSlot.requestedBy.callsign
              }
          } else if (storedSlot.status === "requested" && isAdminOrg){ 
            slotTime.slots[storedSlot.slotType].allRequests = [
              ...slotTime.slots[storedSlot.slotType].allRequests ?? [],
              {
                requestedSlotId: storedSlot.id,
                orgId: storedSlot.requestedBy.orgId,
                orgName: storedSlot.requestedBy.orgName,
                callsign: storedSlot.requestedBy.callsign,
              },
            ];
          }else if (
            !slotsNeedApproval &&
            storedSlot.status === "pending_confirmation" &&
            storedSlot.untilDateTimeEpoch * 1000 > moment(currentTime).toDate().getTime()
          ) {
            slotTime.slots[storedSlot.slotType].pendingConfirmation = {
              untilDateTime: moment(storedSlot.untilDateTimeEpoch * 1000).toDate(),
              by: {
                uid: storedSlot.by.uid,
                orgId: storedSlot.by.orgId,
                orgName: storedSlot.by.orgName,
              },
            };
          }
        }
      }
    });

    if (!isAdminOrg) {
      //create on holds
      tempSlotTimes.forEach((st) => {
        (Object.entries(st.slots) as ["outbound" | "local" | "private", AirfieldSlot][]).forEach(([slotType, slot]) => {
          if (
            !slotsNeedApproval &&
            slotType !== "private" &&
            ((slot.bookedBy && slot.bookedBy!.orgId === selectedOrg!.id) ||
              (slot.pendingConfirmation && slot.pendingConfirmation.by.orgId === selectedOrg!.id))
          ) {
            const startDateTime = moment(st.startDateTime).subtract(slotRestrictions.onHold.before, "minutes").toDate();
            const endDateTime = moment(st.startDateTime).add(slotRestrictions.onHold.after, "minutes").toDate();
            const affectedSlotTimes = tempSlotTimes.filter(
              (s) =>
                s.startDateTime.getTime() >= startDateTime.getTime() &&
                s.startDateTime.getTime() <= endDateTime.getTime() &&
                s.startDateTime.getTime() >=
                  moment(currentTime).add(slotRestrictions.onHold.alwaysAvailableBeforeNow, "minutes").toDate().getTime()
            );
            affectedSlotTimes.forEach((s) => {
              const slot = s.slots[slotType];
              if (slot.available && !slot.onHoldUntil && !slot.bookedBy && !slot.pendingConfirmation) {
                slot.onHoldUntil = moment(s.startDateTime).subtract(slotRestrictions.onHold.alwaysAvailableBeforeNow, "minutes").toDate();
              }
            });
          } else if (
            slot.available &&
            !slot.onHoldUntil &&
            !slot.bookedBy &&
            !slot.pendingConfirmation &&
            st.startDateTime.getTime() <= moment(currentTime).subtract(slotRestrictions.unavailableAfterNow, "minutes").toDate().getTime()
          ) {
            slot.available = false;
          }
        });
      });

      //if have already booked 8 slots, then all othher slots are onHoldUntil 06:10
      const bookedSlots = tempSlotTimes
        .map((st) => Object.values(st.slots))
        .flat()
        .filter((s) => s.bookedBy && s.bookedBy!.orgId === selectedOrg!.id);
      const untilDate = moment(date)
        .utc(false)
        .startOf("day")
        .add(slotRestrictions.maxSlotsPerDay.until.hours, "hours")
        .add(slotRestrictions.maxSlotsPerDay.until.minutes, "minutes")
        .toDate();

      if (!slotsNeedApproval && bookedSlots.length >= slotRestrictions.maxSlotsPerDay.numOfSlots && currentTime.getTime() < untilDate.getTime()) {
        tempSlotTimes.forEach((s) => {
          Object.entries(s.slots).forEach(([key, slot]) => {
            if (slot.available && !slot.onHoldUntil && !slot.bookedBy && !slot.pendingConfirmation) {
              slot.onHoldUntil = untilDate;
            }
          });
        });
      }
    }
    setSlotTimes(tempSlotTimes);
  }, [currentTime, date, hasLoadedStoredSlots, loadedSlotTimes, selectedOrg, slotRestrictions.maxSlotsPerDay.numOfSlots, slotRestrictions.maxSlotsPerDay.until.hours, slotRestrictions.maxSlotsPerDay.until.minutes, slotRestrictions.onHold.after, slotRestrictions.onHold.alwaysAvailableBeforeNow, slotRestrictions.onHold.before, slotRestrictions.unavailableAfterNow, storedSlots, isAdminOrg, slotsNeedApproval]);

  const [bookSlotModalData, setBookSlotModalData] = useState<BookSlotModalData | undefined>(undefined);
  const [deleteSlotModalData, setDeleteSlotModalData] = useState<BookSlotModalData | undefined>(undefined);
  const [tempPendingConfirmationSlotPath, setTempPendingConfirmationSlotPath] = useState<DatabaseReference | undefined>(undefined);
  const [tempPendingConfirmationDisconnectRef, setTempPendingConfirmationDisconnectRef] = useState<OnDisconnect | undefined>();
  const [saving, setSaving] = useState(false);

  const createPendingConfirmationSlot = useCallback(
    (slotTime: AirfieldSlotTime, slotType: AirfieldSlotType) => {
      const newRef = push(ref(database, slotDatePath));
      setTempPendingConfirmationSlotPath(newRef);
      set(newRef, {
        startDateTimeEpoch: slotTime.startDateTime.getTime() / 1000,
        slotType: slotType,
        status: "pending_confirmation",
        untilDateTimeEpoch: Math.round(moment().add(5, "minutes").toDate().getTime() / 1000),
        by: {
          uid: auth.currentUser!.uid,
          orgName: selectedOrg!.name,
          orgId: selectedOrg!.id,
        },
      } as AirfieldSlotStored);
      return newRef;
    },
    [selectedOrg, slotDatePath]
  );

  useEffect(() => {
    if (bookSlotModalData && !tempPendingConfirmationSlotPath) {
      const newRef = createPendingConfirmationSlot(bookSlotModalData.slotTime, bookSlotModalData.slotType);

      const disconnectRef = onDisconnect(newRef);
      disconnectRef.remove();
      setTempPendingConfirmationDisconnectRef(disconnectRef);
    } else if (!bookSlotModalData && tempPendingConfirmationSlotPath) {
      if (tempPendingConfirmationSlotPath) {
        set(tempPendingConfirmationSlotPath, null);
        tempPendingConfirmationDisconnectRef?.cancel();

        setTempPendingConfirmationSlotPath(undefined);
        setTempPendingConfirmationDisconnectRef(undefined);
      }
    }
  }, [bookSlotModalData, createPendingConfirmationSlot, selectedOrg, slotDatePath, tempPendingConfirmationDisconnectRef,tempPendingConfirmationSlotPath]);

  const acceptRequestedSlotPressed = (requestedSlotId: string) => {
    const acceptAsync = async () => {
      setSaving(true);
      try {
        await AviTracerApi.acceptRequestedAirfieldSlot(airfieldId, dateStr!, requestedSlotId);
      } catch (e) {
        alert(e);
      } finally {
        setSaving(false);
      }
    };
    acceptAsync();
  }

  const rejectRequestedSlotPressed = (requestedSlotId: string) => {
    const rejectAsync = async () => {
      setSaving(true);
      try {
        await AviTracerApi.rejectRequestedAirfieldSlot(airfieldId, dateStr!, requestedSlotId);
      } catch (e) {
        alert(e);
      } finally {
        setSaving(false);
      }
    };
    rejectAsync();
  }

  const [filteredSlotType, setFilteredSlotType] = useState<AirfieldSlotType | undefined>(undefined);

  const columnHelper = createColumnHelper<AirfieldSlotTime>();
  var columns: any = [
    columnHelper.accessor("startDateTime", {
      header: "Time (UTC)",
      size: 7,
      cell: (info) => (
        <div className="d-flex align-items-center" style={{height: "120px"}}>
          <div className="fs-1 fw-bold">{moment(info.cell.getValue()).utc(false).format("HH:mm")}</div>
        </div>
      ),
    })
  ]
  if (!filteredSlotType || filteredSlotType === "outbound") {
    columns.push(
      columnHelper.accessor("slots.outbound", {
        header: "Outbound",
        size: 32,
        cell: (info) => (
          <AirfieldSlotCell
            airfieldId={airfieldId}
            selectedOrg={selectedOrg!}
            isAdminOrg={isAdminOrg}
            info={info}
            setTimeForBookedSlotPressed={setTimeForBookedSlotPressed}
            setDeleteSlotModalData={setDeleteSlotModalData}
            setBookSlotModalData={setBookSlotModalData}
            editRestrictionPressed={editRestrictionPressed}
            slotType={"outbound"}
            slotsNeedApproval={slotsNeedApproval}
            bookRequestedSlotPressed={acceptRequestedSlotPressed}
            rejectRequestedSlotPressed={rejectRequestedSlotPressed}
            timeTypesToLog={timeTypesToLog}
          />
        ),
      })
    )
  }
  if (!filteredSlotType || filteredSlotType === "local") {
    columns.push(
      columnHelper.accessor("slots.local", {
        header: "Local",
        size: 32,
        cell: (info) => (
          <AirfieldSlotCell
            airfieldId={airfieldId}
            selectedOrg={selectedOrg!}
            isAdminOrg={isAdminOrg}
            info={info}
            setTimeForBookedSlotPressed={setTimeForBookedSlotPressed}
            setDeleteSlotModalData={setDeleteSlotModalData}
            setBookSlotModalData={setBookSlotModalData}
            editRestrictionPressed={editRestrictionPressed}
            slotType={"local"}
            slotsNeedApproval={slotsNeedApproval}
            bookRequestedSlotPressed={acceptRequestedSlotPressed}
            rejectRequestedSlotPressed={rejectRequestedSlotPressed}
            timeTypesToLog={timeTypesToLog}
          />
        ),
      })
    );
  }

  if (!filteredSlotType || filteredSlotType === "private") {
    columns.push(
      columnHelper.accessor("slots.private", {
        header: "Private",
        size: 29,
        cell: (info) => (
          <AirfieldSlotCell
            airfieldId={airfieldId}
            selectedOrg={selectedOrg!}
            isAdminOrg={isAdminOrg}
            info={info}
            setTimeForBookedSlotPressed={setTimeForBookedSlotPressed}
            setDeleteSlotModalData={setDeleteSlotModalData}
            setBookSlotModalData={setBookSlotModalData}
            editRestrictionPressed={editRestrictionPressed}
            slotType={"private"}
            slotsNeedApproval={slotsNeedApproval}
            bookRequestedSlotPressed={acceptRequestedSlotPressed}
            rejectRequestedSlotPressed={rejectRequestedSlotPressed}
            timeTypesToLog={timeTypesToLog}
          />
        ),
      })
    );
  }

  const table = useReactTable({
    data: slotTimes,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const bookSlotPressed = (callsign: string, remarks: string) => {
    const bookAsync = async () => {
      setSaving(true);
      try {
        await AviTracerApi.bookAirfieldSlot(airfieldId, dateStr!, tempPendingConfirmationSlotPath!.key!, callsign);
        tempPendingConfirmationDisconnectRef?.cancel();

        setTempPendingConfirmationSlotPath(undefined);
        setTempPendingConfirmationDisconnectRef(undefined);
        setBookSlotModalData(undefined);
      } catch (e) {
        alert(e);
      } finally {
        setSaving(false);
      }
    };
    bookAsync();
  };

  const deleteSlotPressed = (bookedSlotId: string) => {
    const deleteAsync = async () => {
      setSaving(true);
      try {
        await AviTracerApi.cancelBookedAirfieldSlot(airfieldId, dateStr!, bookedSlotId);
        setDeleteSlotModalData(undefined);
      } catch (e) {
        alert(e);
      } finally {
        setSaving(false);
      }
    };
    deleteAsync();
  };

  const setTimeForBookedSlotPressed = (bookedSlotId: string, timeType: "blocksOff" | "takeoff" | "landed") => {
    const setTimeAsync = async () => {
      await AviTracerApi.setTimeInAirfieldBookedSlot(airfieldId, dateStr!, bookedSlotId, timeType)
    }
    setTimeAsync()
  }

  const [addRestrictionModalData, setAddRestrictionModalData] = useState<{edittedRestriction?: {
    startDateTimeEpoch: number
    endDateTimeEpoch: number
    reason: string
    editedRestrictionId: string
  }} | undefined>(undefined);

  const saveRestrictionPressed = (fromTime: Date, toTime: Date, remarks: string, edittedRestrictionId?: string) => {
    const addRestrictionAsync = async () => {
      setSaving(true);
      try {
        if (edittedRestrictionId) {
          await AviTracerApi.editAirfieldSlotRestriction(airfieldId, dateStr!, edittedRestrictionId, fromTime, toTime, remarks);
        }else{
          await AviTracerApi.addAirfieldSlotRestriction(airfieldId, dateStr!, fromTime, toTime, remarks);
        }
        
        setAddRestrictionModalData(undefined);
      } catch (e) {
        alert(e);
      } finally {
        setSaving(false);
      }
    };
    addRestrictionAsync();
  };

  const editRestrictionPressed = (restrictionId: string) => {
    const restriction = storedSlots.find((s) => s.id === restrictionId);
    if (restriction && restriction.status === "unavailable") {
      setAddRestrictionModalData({
        edittedRestriction: {
          startDateTimeEpoch: restriction.startDateTimeEpoch,
          endDateTimeEpoch: restriction.endDateTimeEpoch,
          reason: restriction.reason!,
          editedRestrictionId: restriction.id,
        },
      });
    }
  };

  const getRowStyle = useCallback(
    (cell: Cell<AirfieldSlotTime, unknown>) => {
      const isNow = moment(cell.row.original.startDateTime).isBetween(
        moment(currentTime).subtract(10, "minutes"),
        moment(currentTime).add(10, "minutes")
      );

      const getWidth = () => {
        if (filteredSlotType) {
          return cell.column.id === "startDateTime" ? "20%" : "80%";
        } else {
          return `${cell.column.columnDef.size}%`;
        }
      };

      return {
        width: getWidth(),
        backgroundColor: isNow ? "#555" : "white",
        color: isNow ? "white" : "black",
      };
    },
    [currentTime, filteredSlotType]
  );

  const flyMatch = useMatch("/fly/airfield/:airfieldId/slots") 
  const inFlyLayout = useMemo(() => {
    return flyMatch !== null
  }, [flyMatch])

  return (
    <Container fluid={!inFlyLayout} className={"vh-100 ".concat((!inFlyLayout) ? "px-0" : "")}>
      <ListEntitiesHeaderComponent
        title={airfieldId.toUpperCase() + " Slots"}
        actionBtn={ (adminOrgId === selectedOrg?.id) ? {
          title: "Add Restriction",
          onClick: () => {
            setAddRestrictionModalData({});
          },
        } : undefined}
      >
        <FlatPicker
          options={{
            mode: "single",
            maxDate: moment().startOf("day").add(10, "day").toDate(),
            minDate: moment().startOf("day").subtract(10, "day").toDate(),
          }}
          value={date}
          onChange={(dates) => {
            setHasLoadedStoredSlots(false);
            setSearchParams({ date: moment(dates[0]).utc(true).format("DD-MM-YYYY") });
          }}
          render={({ value, onChange }, ref) => (
            <div className="p-3 rounded-lg" style={{ backgroundColor: "#F7F7F7" }}>
              <FontAwesomeIcon icon={faCalendarDays} />
              <input ref={ref} value={""} onChange={(e) => {}} placeholder="Search" className="ps-3 borderless"></input>
            </div>
          )}
        />
      </ListEntitiesHeaderComponent>

      <FilterSlotsButtonsComponent
        filteredSlotType={filteredSlotType}
        setFilteredSlotType={setFilteredSlotType}
      />
      
      {!hasLoadedStoredSlots ? (
        <LoadingComponent text={"Loading slots..."} />
      ) : (
        <Row className="overflow-auto" style={{ height: "calc(100% - 200px)" }}>
          <Col>
            <Table>
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        style={{
                          borderTop: "none",
                          position: "sticky",
                          top: "0",
                          zIndex: "1",
                          background: "white",
                        }}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => (
                  <tr
                    key={row.id}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td
                        style={getRowStyle(cell)}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      )}
      <Modal show={bookSlotModalData !== undefined}>
        {bookSlotModalData && (
          <BookSlotModalComponent
            bookSlotModalData={bookSlotModalData}
            saving={saving}
            savePressed={bookSlotPressed}
            cancelPressed={() => setBookSlotModalData(undefined)}
            slotsNeedApproval={slotsNeedApproval}
            isAdminOrg={isAdminOrg}
          />
        )}
      </Modal>
      <Modal show={deleteSlotModalData !== undefined}>
        {deleteSlotModalData && (
          <CancelBookModalComponent
            isRestriction={deleteSlotModalData.slotTime.slots[deleteSlotModalData.slotType].unavailableSlotId !== undefined}
            saving={saving}
            deletePressed={(isRestriction) => {
              if (!isRestriction){
                const storedSlotId = deleteSlotModalData.slotTime.slots[deleteSlotModalData.slotType].selectedOrgRequest?.requestedSlotId || deleteSlotModalData.slotTime.slots[deleteSlotModalData.slotType].bookedBy!.bookedSlotId;
                deleteSlotPressed(storedSlotId);
              }else{
                deleteSlotPressed(deleteSlotModalData.slotTime.slots[deleteSlotModalData.slotType].unavailableSlotId!);
              }
            }}
            cancelPressed={() => {
              setDeleteSlotModalData(undefined);
            }}
          />
        )}
      </Modal>
      <Modal show={addRestrictionModalData !== undefined}>
        {addRestrictionModalData && (
          <AddRestrictionModalComponent
            edittedRestriction={addRestrictionModalData.edittedRestriction}
            saving={saving}
            date={date}
            saveRestrictionPressed={saveRestrictionPressed}
            cancelPressed={() => {
              setAddRestrictionModalData(undefined);
            }}
          />
        )}
      </Modal>
    </Container>
  );
}

export default AirfieldSlotsSelectPage;


interface AirfieldSlotCellProps {
  airfieldId: string;
  selectedOrg: Organization;
  isAdminOrg: boolean;
  info: CellContext<AirfieldSlotTime, AirfieldSlot>;
  setBookSlotModalData: React.Dispatch<React.SetStateAction<BookSlotModalData | undefined>>;
  slotType: AirfieldSlotType;
  setDeleteSlotModalData: React.Dispatch<React.SetStateAction<BookSlotModalData | undefined>>;
  setTimeForBookedSlotPressed: (bookedSlotId: string, timeType: "blocksOff" | "takeoff" | "landed") => void;
  editRestrictionPressed: (restrictionId: string) => void;
  bookRequestedSlotPressed: (requestedSlotId: string) => void;
  rejectRequestedSlotPressed: (requestedSlotId: string) => void;
  slotsNeedApproval: boolean;
  timeTypesToLog: ("blocksOff" | "takeoff" | "landed")[]
}

function AirfieldSlotCell({ airfieldId, selectedOrg, isAdminOrg, info, setBookSlotModalData, slotType, setDeleteSlotModalData, setTimeForBookedSlotPressed, editRestrictionPressed, slotsNeedApproval, bookRequestedSlotPressed, rejectRequestedSlotPressed, timeTypesToLog }: AirfieldSlotCellProps) {
  const slot = info.cell.getValue();

  const navigate = useNavigate();

  const [inlineSaving, setInlineSaving] = useState(false);

  if (slot.pendingConfirmation) {
    return (
      <Alert variant="secondary" className="d-flex justify-content-between align-items-center mb-0" style={{ height: "120px" }}>
        <strong>Pending Confirmation</strong>
      </Alert>
    );
  } else if (slot.onHoldUntil) {
    return (
      <Alert variant="secondary" className="d-flex justify-content-between align-items-center mb-0" style={{ height: "120px" }}>
        <div>
          On Hold Until <strong>{moment(slot.onHoldUntil).utc(false).format("HH:mm")}Z</strong>
        </div>
      </Alert>
    );
  } else if (slot.selectedOrgRequest) {
    return (
      <Alert
        variant={"warning"}
        className="d-flex justify-content-between align-items-center p-sm-2 p-xxl-3 mb-0 py-2"
        style={{ height: "120px" }}
      >
        <div>
          Requested for <strong>{slot.selectedOrgRequest.callsign}</strong>
        </div>
        <Button
          variant="danger"
          className="px-3"
          onClick={() => {
            setDeleteSlotModalData({ slotTime: info.row.original, slotType });
          }}
        >
          <FontAwesomeIcon icon={faTrash} color="white" />
        </Button>
      </Alert>
    );
  } else if (slot.allRequests) {
    return (
      <Alert
        variant={"warning"}
        className="d-flex justify-content-between align-items-center p-sm-2 p-xxl-3 mb-0 py-2"
        style={{ minHeight: "120px" }}
      >
        <div className="d-flex flex-column">
          {slot.allRequests.map((request) => (
            <div key={request.orgId} className="py-1">
              <strong>{request.callsign}</strong> ({request.orgName})
              <Button
                variant="success"
                disabled={inlineSaving}
                className="mx-1"
                size="sm"
                onClick={() => {
                  setInlineSaving(true);
                  bookRequestedSlotPressed(request.requestedSlotId);
                }}>
                Accept
              </Button>
              <Button
                variant="danger"
                disabled={inlineSaving}
                size="sm"
                onClick={() => {
                  setInlineSaving(true);
                  rejectRequestedSlotPressed(request.requestedSlotId);
                }}
              >
                Reject
              </Button>
            </div>
          ))}
        </div>
      </Alert>
    );
  } else if (slot.bookedBy) {
    return (
      <Alert
        variant={slot.bookedBy.orgId === selectedOrg.id ? "success" : "warning"}
        className="d-flex justify-content-between align-items-center p-sm-2 p-xxl-3 mb-0 py-2"
        style={{ height: "120px" }}
      >
        <div className="w-50">
          <strong>{slot.bookedBy!.callsign}</strong>{" "}
          {slot.bookedBy.orgId !== selectedOrg.id && (
            <>
              <div className="d-none d-xl-block">
                <small>({slot.bookedBy!.orgName})</small>
              </div>
              <span className="d-xl-none">
                <small>({slot.bookedBy!.orgName})</small>
              </span>
            </>
          )}
        </div>
        <Row className="w-50 justify-content-end gy-1 align-items-center">
          {slot.bookedBy.orgId === selectedOrg.id && !isAdminOrg && (
            <>
              {slot.bookedBy!.flightId ? (
                <Col>
                  <Button
                    className="w-100 h-100"
                    onClick={() => {
                      if (selectedOrg.role === "admin"){
                        navigate("/flights/" + slot.bookedBy!.flightId);  
                      }else{
                        navigate("/fly/" + slot.bookedBy!.flightId);
                      }
                    }}
                  >
                    Go to Flight
                  </Button>
                </Col>
              ) : (
                <>
                  {selectedOrg.role === "admin" && (
                    <Col>
                      <Button
                        className="w-100 h-100"
                        onClick={() => {
                          navigate(
                            `/flights/add?depAirfieldId=${airfieldId}&departureDateTime=${
                              info.row.original.startDateTime.getTime() / 1000
                            }&slotType=${slotType}&bookedSlotKey=${slot.bookedBy!.bookedSlotId}`
                          );
                        }}
                      >
                        Create Flight
                      </Button>
                    </Col>
                  )}
                </>
              )}
            </>
          )}
          {isAdminOrg && (
            <>
              {timeTypesToLog.includes("landed") && (
                <Col className="d-flex flex-column align-items-center justify-content-center">
                  <div>Land:</div>
                  {slot.landedEpoch ? (
                    <strong>
                      {moment(slot.landedEpoch * 1000)
                        .utc(false)
                        .format("HH:mm")}
                    </strong>
                  ) : (
                    <Button
                      className="h-100"
                      disabled={inlineSaving}
                      onClick={() => {
                        setInlineSaving(true);
                        setTimeForBookedSlotPressed(slot.bookedBy!.bookedSlotId, "landed");
                      }}
                    >
                      Set
                    </Button>
                  )}
                </Col>
              )}
              {timeTypesToLog.includes("blocksOff") && (
                <Col className="d-flex flex-column align-items-center justify-content-center">
                  <div>Start:</div>
                  {slot.blocksOffEpoch ? (
                    <strong>
                      {moment(slot.blocksOffEpoch * 1000)
                        .utc(false)
                        .format("HH:mm")}
                    </strong>
                  ) : (
                    <Button
                      className="h-100"
                      disabled={inlineSaving}
                      onClick={() => {
                        setInlineSaving(true);
                        setTimeForBookedSlotPressed(slot.bookedBy!.bookedSlotId, "blocksOff");
                      }}
                    >
                      Set
                    </Button>
                  )}
                </Col>
              )}
              {timeTypesToLog.includes("takeoff") && (
                <Col className="d-flex flex-column align-items-center justify-content-center">
                  <div>T/O:</div>
                  {slot.takeoffEpoch ? (
                    <strong>
                      {moment(slot.takeoffEpoch * 1000)
                        .utc(false)
                        .format("HH:mm")}
                    </strong>
                  ) : (
                    <Button
                      className="h-100"
                      disabled={inlineSaving}
                      onClick={() => {
                        setInlineSaving(true);
                        setTimeForBookedSlotPressed(slot.bookedBy!.bookedSlotId, "takeoff");
                      }}
                    >
                      Set
                    </Button>
                  )}
                </Col>
              )}
            </>
          )}

          {(slot.bookedBy.orgId === selectedOrg.id || isAdminOrg) && (
            <Col>
              <Button
                variant="danger"
                className="w-100"
                onClick={() => {
                  setDeleteSlotModalData({ slotTime: info.row.original, slotType });
                }}
              >
                <FontAwesomeIcon icon={faTrash} color="white" />
              </Button>
            </Col>
          )}
        </Row>
      </Alert>
    );
  } else if (slot.available) {
    return (
      <Alert variant="primary" className="d-flex justify-content-between align-items-center mb-0" style={{ height: "120px" }}>
        <Button
          className="w-100"
          onClick={() => {
            setBookSlotModalData({ slotTime: info.row.original, slotType });
          }}
        >
          {(slotsNeedApproval && !isAdminOrg) ? "Request" : "Book"} Slot
        </Button>
      </Alert>
    );
  } else if (!slot.available) {
    return (
      <Alert
        variant={slot.unavailableReason ? "danger" : "light"}
        className="d-flex justify-content-between align-items-center mb-0"
        style={{ height: "120px" }}
      >
        <div>{slot.unavailableReason ? <strong>{slot.unavailableReason}</strong> : <>Not available</>}</div>
        {isAdminOrg && (
          <>
            <Button
              onClick={() => {
                setBookSlotModalData({ slotTime: info.row.original, slotType });
              }}
            >
              Book Slot Override
            </Button>
            {info.row.original.slots[slotType].unavailableSlotId && slotType !== "private" && (
              <div className="d-flex flex-column">
                <Button
                  size="sm"
                  className="m-1"
                  variant="secondary"
                  onClick={() => {
                    editRestrictionPressed(info.row.original.slots[slotType].unavailableSlotId!);
                  }}
                >
                  Edit
                </Button>
                <Button
                  variant="danger"
                  size="sm"
                  onClick={() => {
                    setDeleteSlotModalData({ slotTime: info.row.original, slotType });
                  }}
                >
                  Delete
                </Button>
              </div>
            )}
          </>
        )}
      </Alert>
    );
  }
  return <></>
}


type BookSlotModalData = {slotTime: AirfieldSlotTime, slotType: AirfieldSlotType};

interface BookSlotModalComponentProps {
  bookSlotModalData: BookSlotModalData
  saving: boolean
  savePressed: (callsign: string, remarks: string) => void
  cancelPressed: () => void
  slotsNeedApproval: boolean
  isAdminOrg: boolean
}

function BookSlotModalComponent({bookSlotModalData, saving, savePressed, cancelPressed, slotsNeedApproval, isAdminOrg}: BookSlotModalComponentProps) {

  const [newCallsign, setNewCallsign] = useState("")
  const [newRemarks, setNewRemarks] = useState("")

  return (
    <>
      <Modal.Header>
        <Modal.Title>{(slotsNeedApproval && !isAdminOrg) ? "Request" : "Book"} Slot</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="gy-2 gx-2">
          <Col xs={6}>
            <ReadOnlyInputBox
              name={"Date"}
              isDisabled={saving}
              value={moment(bookSlotModalData.slotTime.startDateTime).utc(false).format("DD/MM/YYYY")}
            />
          </Col>
          <Col xs={6}>
            <ReadOnlyInputBox
              name={"Time (UTC)"}
              isDisabled={saving}
              value={moment(bookSlotModalData.slotTime.startDateTime).utc(false).format("HH:mm")}
            />
          </Col>
          <Col xs={12}>
            <ReadOnlyInputBox
              name={"Slot"}
              isDisabled={saving}
              value={bookSlotModalData.slotType.slice(0,1).toUpperCase() + bookSlotModalData.slotType.slice(1).toLowerCase()}
            />
          </Col>
          <Col xs={12}>
            <InputBox
              value={newCallsign}
              isDisabled={saving}
              name="Aircraft Callsign"
              uppercase={true}
              onChange={(v) => setNewCallsign(v)}
            />
          </Col>
          <Col xs={12}>
            <TextAreaBox name={"Remarks"} rows={3} isDisabled={saving} value={newRemarks} onChange={(v) => setNewRemarks(v)} />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Row className="w-100 mx-0 gx-2 ">
          <Col xs={3}>
            <Button className="w-100" variant="light" disabled={saving} onClick={cancelPressed}>
              Cancel
            </Button>
          </Col>
          <Col xs={9}>
            <Button className="w-100" disabled={saving} onClick={() => savePressed(newCallsign, newRemarks)}>
              {(slotsNeedApproval && !isAdminOrg) ? "Request" : "Book"} Slot
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </>
  );

}

interface CancelBookModalComponentProps {
  saving: boolean
  deletePressed: (isRestriction: boolean) => void
  cancelPressed: () => void
  isRestriction: boolean
}

function CancelBookModalComponent({saving, deletePressed, cancelPressed, isRestriction}: CancelBookModalComponentProps) {
  return (
    <>
      <Modal.Header>
        <Modal.Title>Cancel {isRestriction ? "Restriction" : "Slot" }?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to cancel this {isRestriction ? "restriction" : "booking"}?</p>
        <p>Please note that this action is irreversible. You will need to book a new slot if you wish to reschedule.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" disabled={saving} onClick={cancelPressed}>
          Keep {isRestriction ? "Restriction" : "Slot" }
        </Button>
        <Button variant="outline-danger" disabled={saving} onClick={() => deletePressed(isRestriction)}>
          Delete {isRestriction ? "Restriction" : "Slot" }
        </Button>
      </Modal.Footer>
    </>
  );
}

interface AddRestrictionModalComponentProps {
  saving: boolean
  date: Date
  saveRestrictionPressed: (fromTime: Date, toTime: Date, remarks: string, edittedRestrictionId?: string) => void
  cancelPressed: () => void
  edittedRestriction?: {
    startDateTimeEpoch: number
    endDateTimeEpoch: number
    reason: string
    editedRestrictionId: string
  }
}

function AddRestrictionModalComponent({saving, date, saveRestrictionPressed, cancelPressed, edittedRestriction}: AddRestrictionModalComponentProps) {

  const restrictionDate = date;

  const [restrictionFromTime, setRestrictionFromTime] = useState(edittedRestriction ? moment(edittedRestriction.startDateTimeEpoch * 1000).utc(false).format("HHmm") : "")
  const [restrictionToTime, setRestrictionToTime] = useState(edittedRestriction ? moment(edittedRestriction.endDateTimeEpoch * 1000).utc(false).format("HHmm") : "")
  const [restrictionRemarks, setRestrictionRemarks] = useState(edittedRestriction ? edittedRestriction.reason : "")

  const [fromInvalidError, setFromInvalidError] = useState("")
  const [toInvalidError, setToInvalidError] = useState("")

  const checkForTimeErrors = () => {
    const fromTime = moment(restrictionFromTime, "HHmm", true)
    const toTime = moment(restrictionToTime, "HHmm", true)

    if (!fromTime.isValid()) {
      setFromInvalidError("Invalid time format. Must be in HHmm format")
    } else {
      setFromInvalidError("")
    }

    if (!toTime.isValid()) {
      setToInvalidError("Invalid time format. Must be in HHmm format")
    } else {
      setToInvalidError("")
    }

    if (fromTime.isValid() && toTime.isValid()) {
      if (fromTime.isAfter(toTime)) {
        setFromInvalidError("From time must be before to time")
        setToInvalidError("To time must be after from time")
      }
    }
  }

  return (
    <>
      <Modal.Header>
        <Modal.Title>Add Restriction</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="gy-2 gx-2">
          <Col xs={12}>
            <ReadOnlyInputBox name={"Date"} isDisabled={saving} value={moment(restrictionDate).utc(false).format("DD/MM/YYYY")} />
          </Col>
          <Col xs={12}>
            <InputBox
              value={restrictionFromTime}
              name={"From Time (UTC) - Format: HHmm"}
              isDisabled={saving}
              type={"text"}
              onChange={(v) => {
                setFromInvalidError("");
                setRestrictionFromTime(v);
              }}
              invalidError={fromInvalidError}
            />
          </Col>
          <Col xs={12}>
            <InputBox
              value={restrictionToTime}
              name={"To Time (UTC) - Format: HHmm"}
              isDisabled={saving}
              type={"text"}
              onChange={(v) => {
                setToInvalidError("");
                setRestrictionToTime(v);
              }}
              invalidError={toInvalidError}
            />
          </Col>
          <Col xs={12}>
            <InputBox
              value={restrictionRemarks}
              name={"Remarks"}
              isDisabled={saving}
              type={"text"}
              onChange={(v) => {
                setRestrictionRemarks(v);
              }}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={saving} variant="light" onClick={cancelPressed}>
          Cancel
        </Button>
        <Button
          disabled={saving}
          onClick={() => {
            checkForTimeErrors();
            if (!fromInvalidError && !toInvalidError) {
              saveRestrictionPressed(
                moment(restrictionDate)
                  .utc(false)
                  .startOf("day")
                  .add(parseInt(restrictionFromTime.slice(0, 2)), "hours")
                  .add(parseInt(restrictionFromTime.slice(2, 4)), "minutes")
                  .toDate(),
                moment(restrictionDate)
                  .utc(false)
                  .startOf("day")
                  .add(parseInt(restrictionToTime.slice(0, 2)), "hours")
                  .add(parseInt(restrictionToTime.slice(2, 4)), "minutes")
                  .toDate(),
                restrictionRemarks,
                edittedRestriction?.editedRestrictionId
              );
            }
          }}
        >
          {edittedRestriction ? "Edit Restriction" : "Add Restriction"}
        </Button>
      </Modal.Footer>
    </>
  );
}


interface FilterSlotsButtonsComponentProps {
  filteredSlotType: AirfieldSlotType | undefined;
  setFilteredSlotType: React.Dispatch<React.SetStateAction<AirfieldSlotType | undefined>>;
}

function FilterSlotsButtonsComponent({filteredSlotType, setFilteredSlotType}: FilterSlotsButtonsComponentProps) {
  const isSmallerThanXll = useMediaQuery("(max-width: 1400px)");
  useEffect(() => {
    if (isSmallerThanXll) {
      setFilteredSlotType("outbound");
    } else {
      setFilteredSlotType(undefined);
    }
  }, [isSmallerThanXll, setFilteredSlotType]);

  return (
    <div className="d-flex pb-2">
      {!isSmallerThanXll && (
        <Button
          variant="dark"
          disabled={filteredSlotType === undefined}
          className="me-2"
          onClick={() => {
            setFilteredSlotType(undefined);
          }}
        >
          All
        </Button>
      )}
      <Button
        className="me-2"
        disabled={filteredSlotType === "outbound"}
        variant="dark"
        onClick={() => {
          setFilteredSlotType("outbound");
        }}
      >
        Outbound
      </Button>
      <Button
        className="me-2"
        disabled={filteredSlotType === "local"}
        variant="dark"
        onClick={() => {
          setFilteredSlotType("local");
        }}
      >
        Local
      </Button>
      <Button
        disabled={filteredSlotType === "private"}
        variant="dark"
        onClick={() => {
          setFilteredSlotType("private");
        }}
      >
        Private
      </Button>
    </div>
  );
}