import { CellContext, createColumnHelper } from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { Badge, Button, Col, Container, Dropdown, Modal, Offcanvas, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap";
import { Outlet, useMatch, useNavigate, useSearchParams } from "react-router-dom";
import ListEntitiesHeaderComponent from "../../../components/ListEntities/ListEntitiesHeaderComponent"
import ListEntitiesTable from "../../../components/ListEntities/ListEntitiesTable";
import Flight, { FlightBriefing, flightIsWaitingForRiskAssessment } from "../../../models/flight";
import { getFlights, getFlightsInRange, markFlightBriefingAsInProgress, markFlightBriefingAsReady, refreshFlights, requestBriefingCreation } from "../../../store/flightSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { useInterval } from "usehooks-ts";
import { AviTracerApi } from "../../../avitracerApi";
import moment from 'moment'
import FlatPicker from 'react-flatpickr'
import ListFlightsTabsComponent from "./ListFlightsTabsComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendarDays, faXmark } from '@fortawesome/free-solid-svg-icons'
import { faCircleDown } from "@fortawesome/free-regular-svg-icons";
import LoadingComponent from "../../../components/LoadingComponent";
import { Player } from "@lottiefiles/react-lottie-player";
import { getDisplayableTextFromFlightPlanPoint } from "../../../models/flightPlan";
import { selectedOrganization } from "../../../store/organizationsSlice";
import { Passenger, getPassengerFullName, getPassengerLastNameWithInitials } from "../../../models/passenger";
import { Organization } from "../../../models/organization";
import { getCrewLastNameWithInitials } from "../../../models/crew";

export type ListFlightsContext = {setSelectedTab: React.Dispatch<React.SetStateAction<"today" | "tomorrow" | "upcoming" | "archived">>}

function ListFlightsPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [ searchParams, setSearchParams ] = useSearchParams();
  const match = useMatch("/flights")

  const [ archivedRange, setArchivedRange ] = useState<{from: Date, to: Date}>({
    from: searchParams.has("from") ? moment(searchParams.get("from") as string).utc(true).toDate() : moment().utc(true).subtract(10, "day").toDate(), 
    to: searchParams.has("to") ? moment(searchParams.get("to") as string).utc(true).toDate() : moment().utc(true).subtract(1, "day").toDate()
  })
  
  const [ selectedTab, setSelectedTab] = useState<"today" | "tomorrow" | "upcoming" | "archived">(searchParams.get("tab") as "today" | "tomorrow" | "upcoming" | "archived" || "today");

  const { allFlights, status, loading } = useAppSelector((state) => state.flights);
  const [filteredFlights, setFilteredFlights] = useState<Flight[]>([]);

  useEffect(() => {
    if (status === "idle"){
      dispatch(getFlights())
    }

  },[dispatch, status])

  useInterval( async () => {
    const hasScheduledFlightsInPast = allFlights.some((flight) => {
      return (flight.fplFile.status === "scheduled" && moment(flight.fplFile.scheduleTime).isBefore(moment())) || 
              (flight.briefing?.status === "scheduled" && moment(flight.briefing?.scheduleTime).isBefore(moment()));
    });
    if (hasScheduledFlightsInPast ){
      dispatch(refreshFlights())
    }
  }, 60000);
  
  useEffect(() => {
    if (match){
      if (selectedTab === "today") {
        setSearchParams({}, {replace: true});
      } else if (selectedTab === "tomorrow") {
        setSearchParams({
          tab: "tomorrow"
        }, {replace: true});
      }else if (selectedTab === "upcoming") {
        setSearchParams({
          tab: "upcoming"
        }, {replace: true});
      } else if (selectedTab === "archived") {
        setSearchParams({
          tab: "archived",
          from: moment(archivedRange.from).format("Y-M-D"),
          to: moment(archivedRange.to).format("Y-M-D")
        }, {replace: true});
      }
    }
  }, [selectedTab, archivedRange, setSearchParams, match])

  useEffect( () => {
    if (selectedTab === "archived") {
      const getFlightsInArchivedRange = async () => {
        await dispatch(getFlightsInRange({
          from: archivedRange.from,
          to: moment(archivedRange.to).utc(true).endOf("day").toDate()
        })).unwrap();
      };
      getFlightsInArchivedRange();
    }
  }, [dispatch, archivedRange, selectedTab]);

  useEffect(() => {
    var from: Date;
    var to: Date;

    if (selectedTab === "today") {
      from = moment().utc().startOf("date").toDate();
      to = moment().utc().endOf("date").toDate();
    } else if (selectedTab === "tomorrow") {
      from = moment().utc().add(1, "day").startOf("date").toDate();
      to = moment().utc().add(1, "day").endOf("date").toDate();
    } else if (selectedTab === "upcoming") {
      from = moment().utc().add(1, "day").startOf("date").toDate();
      to = moment().utc().add(1, "year").endOf("date").toDate();
    } else if (selectedTab === "archived") {
      from = moment(archivedRange.from).utc().startOf("date").toDate();
      to =  moment(archivedRange.to).utc().endOf("date").toDate()
    }
    setFilteredFlights(
      allFlights.filter((f) => {
        const currDate = new Date(f.departureDateTime);
        return currDate >= from && currDate <= to;
      })
    );
    
  }, [allFlights, archivedRange, selectedTab])

  return (
    <Container fluid className="vh-100 px-0 px-md-2">
      <ListEntitiesHeaderComponent
        title="Flights"
        actionBtn={{
          title: "Create Flight",
          onClick: () => navigate("add"),
        }}
      >
        <>
          {selectedTab === "archived" && (
            <FlatPicker
              options={{
                mode: "range",
                maxDate: moment().endOf("day").subtract(1, "day").toDate(),
              }}
              value={[archivedRange.from, archivedRange.to]}
              onChange={(dates) => {
                if (dates.length === 2) {
                  setArchivedRange({ from: moment(dates[0]).utc(true).toDate(), to: moment(dates[1]).utc(true).toDate() });
                }
              }}
              render={({ value, onChange }, ref) => (
                <div className="p-3 rounded-lg" style={{ backgroundColor: "#F7F7F7" }}>
                  <FontAwesomeIcon icon={faCalendarDays} />
                  <input ref={ref} value={""} onChange={(e) => {}} placeholder="Search" className="ps-3 borderless"></input>
                </div>
              )}
            />
          )}
        </>
      </ListEntitiesHeaderComponent>

      <ListFlightsTabsComponent selectedTab={selectedTab} setSelectedTab={setSelectedTab} />

      {loading ? (
        <LoadingComponent text="Loading flights..." />
      ) : (
        <>
          {filteredFlights.length === 0 ? (
            <NoFlightsComponent/>
          ) : (
            <Row className="overflow-auto" style={{ height: "calc(100% - 186px)" }}>
              <Col>
                <FlightsTable data={filteredFlights} />
              </Col>
            </Row>
          )}
        </>
      )}

      <Outlet context={{setSelectedTab}}/>
    </Container>
  );
}

export default ListFlightsPage


interface FlightsTableProps{
  data: Flight[]
}

function FlightsTable({ data }: FlightsTableProps) {
  const columnHelper = createColumnHelper<Flight>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const selectedOrg = useAppSelector(selectedOrganization);

  const passengersCellContent = (info: CellContext<Flight, unknown>) => {
    const textValue =
      info.row.original.passengers.length > 0
        ? getPassengerLastNameWithInitials(info.row.original.passengers[0])
        : "No PAX";
    const passengers = info.row.original.passengers;
    return <PassengersCellContentComponent textValue={textValue} passengers={passengers} />;
  };

  
  const [isGeneratingBriefing, setIsGeneratingBriefing] = useState(false);
  const inProgressBriefings: Flight[] = data.filter((flight) => flight.briefing?.status === "in-progress");
  const inQueueBriefings: Flight[] = data.filter((flight) => flight.briefing?.status === "in-queue");

  const [isCheckingStatus, setIsCheckingStatus] = useState(false);

  useInterval( async () => {
    if (!isCheckingStatus){
      setIsCheckingStatus(true)
      if (inProgressBriefings.length > 0) {
        for (const flight of inProgressBriefings) {
          const status = (await AviTracerApi.getBriefingStatus(flight.id!)).status;
          if (status === "ready") {
            dispatch(markFlightBriefingAsReady(flight));
          }
        }
      } else if (inQueueBriefings.length > 0) {
        for (const flight of inQueueBriefings) {
          const status = (await AviTracerApi.getBriefingStatus(flight.id!)).status;

          if (status === "in-progress") {
            dispatch(markFlightBriefingAsInProgress(flight));
          } else if (status === "ready") {
            dispatch(markFlightBriefingAsReady(flight));
          }
        }
      }
      setIsCheckingStatus(false)
    }

  }, (inProgressBriefings.length + inQueueBriefings.length) > 0 ? 10000 : null);

  const briefingStatusBadgeForFlight = (flight: Flight): JSX.Element => {
    const briefingStatus = flight.briefing?.status;
    if (!briefingStatus || briefingStatus === "not-started") {
      if (selectedOrg?.billing.canCreateBriefingPack) {
        return <Badge bg="warning" text="dark">Not Generated</Badge>
      }else{
        return <Badge bg="dark" text="light">No credits</Badge>;
      }
    } else if (briefingStatus === "error") {
      return (
        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip id="button-tooltip-2">
              <div className="p-1 px-3">{flight.briefing!.error}</div>
            </Tooltip>
          }
        >
          <Badge bg="danger">Error</Badge>
        </OverlayTrigger>
      );
    } else if (briefingStatus === "scheduled") {
      if (moment(flight.briefing!.scheduleTime).dayOfYear() === moment(flight.departureDateTime).dayOfYear()){
        return <Badge bg="info" text="dark">Scheduled {moment(flight.briefing!.scheduleTime).utc().format("HH:mm")}</Badge>
      }else{
        return <Badge bg="info" text="dark">Scheduled {moment(flight.briefing!.scheduleTime).utc().format("DD/MM HH:mm")}</Badge>
      }
    } else if (briefingStatus === "in-queue") {
      return <Badge bg="info" text="dark">In Queue</Badge>
    } else if (briefingStatus === "in-progress") {
      return <Spinner variant="dark" animation="border" size="sm" />;
    } else if (briefingStatus === "ready") {
      if (flight.briefingPilotAccept !== undefined){
        return (
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="button-tooltip-2">
                <div className="p-1 px-3">{moment(flight.briefingPilotAccept.time).utc().format("DD/MM/YYYY HH:mm")} UTC</div>
              </Tooltip>
            }
          >
            <Badge bg="primary">Signed</Badge>
          </OverlayTrigger>
        );
      }else{
        return <Badge bg="success">Ready</Badge>;
      }
      
    }
    return <></>;
  }

  const fplFileStatusBadgeForFlight = (flight: Flight, selectedOrg?: Organization): JSX.Element => {
    if (flight.fplFile.status === "error") {
      return (
        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip id="button-tooltip-2">
              <div className="p-1 px-3">{flight.fplFile.error}</div>
            </Tooltip>
          }
        >
          <Badge bg="danger">Error</Badge>
        </OverlayTrigger>
      );
    }else if (flight.fplFile.status === "not-filed") {
      return <Badge bg="secondary" >Not filed</Badge>;
    }else if (flight.fplFile.status === "ready-to-file") {
      if (selectedOrg && flightIsWaitingForRiskAssessment(flight, selectedOrg)){
        return <Badge bg="danger">Pending Risk</Badge>
      }

      if (selectedOrg && flight.customDocs?.riskAssessment?.acceptanceStatus === false){
        return <Badge bg="danger">Risk rejected</Badge>
      }

      if (selectedOrg?.billing.canFileFpl) {
        return <Badge bg="warning" text="dark">Ready to File</Badge>;
      }else{
        return <Badge bg="dark" text="light">No credits</Badge>;
      }

    }else if (flight.fplFile.status === "scheduled") {
      if (moment(flight.fplFile!.scheduleTime).dayOfYear() === moment(flight.departureDateTime).dayOfYear()){
        return <Badge bg="info" text="dark">Scheduled {moment(flight.fplFile!.scheduleTime).utc().format("HH:mm")}</Badge>
      }else{
        return <Badge bg="info" text="dark">Scheduled {moment(flight.fplFile!.scheduleTime).utc().format("DD/MM HH:mm")}</Badge>
      }
    }else if (flight.fplFile.status === "filed") {
      return <Badge bg="success">Filed</Badge>;
    }else if (flight.fplFile.status === "delayed") {
      return (
        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip id="button-tooltip-2">
              <div className="p-1 px-3">{moment(flight.fplFile.newTime).utc().format("HH:mm") + " UTC"}</div>
            </Tooltip>
          }
        >
          <Badge bg="info" text="dark">Delayed</Badge>
        </OverlayTrigger>
      );
    }
    return <></>;
  }

  const riskAssessmentBadgeForFlight = (flight: Flight, selectedOrg?: Organization): JSX.Element => {
    if (selectedOrg?.riskAssessment.enabled) {
      
      if (selectedOrg.riskAssessment.flightCategories.includes(flight.flightCategory)) {
        const docs = flight.customDocs;
        if (!docs || Object.keys(docs).length === 0) {
          return <Badge bg="warning" text="dark">Pending</Badge>
        } else if (docs.riskAssessment) {
          const level = docs.riskAssessment!.level;
          const needsSign = level.requestSignFrom !== undefined;
          const isSigned = docs.riskAssessmentSigned !== undefined;
          if (needsSign) {
            if (isSigned) {
              if (docs.riskAssessment!.acceptanceStatus === false) {
                return <Badge bg="danger">{level.name} - Rejected</Badge>;
              } else {
                return <Badge bg="primary">{level.name} - Accepted</Badge>;
              }
            } else {
              return <Badge bg="warning" text="dark">{level.name} - Pending sign</Badge>
            }
          } else if (docs.riskAssessment!.acceptanceStatus === false) {
            return <Badge bg="danger">{level.name} - Rejected</Badge>;
          } else {
            return <Badge bg="success">{level.name}</Badge>;
          }
        }
      } else {
        return <Badge bg="secondary">N/A</Badge>;
      }
    } else {
      return <Badge bg="secondary">Disabled</Badge>;
    }
    return <></>;
  }

  const generateBriefing = async (flight: Flight) => {
    setIsGeneratingBriefing(true);
    try {
      await dispatch(requestBriefingCreation(flight)).unwrap()
    }catch(e: any){
      if (e.message === "No autorouter token."){
        navigate("/autorouter")
      }else{
        alert(e.message)
        console.log(e)
      }
    }finally{
      setIsGeneratingBriefing(false);
    }
  }

  const downloadPdfDoc = async (flight: Flight, docName: string) => {
    const pdfData = await AviTracerApi.getPdfDoc(flight.id!, docName)
    const pdfBlob = new Blob([pdfData], {type: 'application/pdf'});
    var pdfURL = window.URL.createObjectURL(pdfBlob);

    const link = document.createElement('a');
    link.download = flight.flightId + "-" + moment(flight.departureDateTime).utc().format("DD-MM-YY-HHmm") + "-" + ((docName === "full") ? "briefing" : docName) + '.pdf';
    link.href = pdfURL;
    link.target = '_blank';
    document.body.appendChild(link);
    
    link.click();
  }

  const downloadCustomPdfDoc = async (flight: Flight, docName: string, signed: boolean) => {
    const pdfData = await AviTracerApi.getCustomPdfDoc(flight.id!, docName, signed)
    const pdfBlob = new Blob([pdfData], {type: 'application/pdf'});
    var pdfURL = window.URL.createObjectURL(pdfBlob);

    const link = document.createElement('a');
    link.download = flight.flightId + "-" + moment(flight.departureDateTime).utc().format("DD-MM-YY-HHmm") + "-" + ((docName === "full") ? "briefing" : docName) + '.pdf';
    link.href = pdfURL;
    link.target = '_blank';
    document.body.appendChild(link);
    
    link.click();
  }

  const columns = [
    columnHelper.accessor((row) => moment(row.departureDateTime).toDate(), {
      id: "departureDateTime",
      header: () => "Date & Time",
      sortingFn: "datetime",
      cell: (info) => (
        <>
          {moment(info.getValue()).utc().format("D/M/YY")} <strong>{moment(info.getValue()).utc().format("HH:mm")}</strong>
          {info.row.original.airfieldSlots?.departure && <div><small className="text-success">{info.row.original.airfieldSlots?.departure.airfieldId?.toUpperCase()} Slot</small></div>}
        </>
      ),
    }),
    columnHelper.accessor("flightId", {
      header: () => "Aircraft",
      cell: (info) => {
        return (
          <div>
            {info.row.original.aircraft.registration.replace("-", "") !== info.row.original.flightId ? (
              <>
                <strong>{info.row.original.flightId}</strong>
                <div>
                  {info.row.original.aircraft.registration} ({info.row.original.aircraft.icaoType})
                </div>
              </>
            ) : (
              <>
                <strong>{info.row.original.aircraft.registration}</strong>
                <div>({info.row.original.aircraft.icaoType})</div>
              </>
            )}
          </div>
        );
      },
      sortingFn: "alphanumeric",
    }),
    columnHelper.accessor("pic.lastName", {
      header: () => "PIC",
      cell: (info) => {
        return (
          <>
            <strong>{info.getValue()}</strong>
            <div>{(info.row.original.flightCategory !== "Training") ? info.row.original.flightCategory : info.row.original.trainingFlightCategory!}</div>
          </>
        );
      },
      sortingFn: "alphanumeric",
    }),
    columnHelper.display(
      {
        id: "POB",
        header: () => "POB",
        cell: (info) => {
          const row = info.row.original
          if (row.flightCategory === "Training") {
            return (
              <>
                {row.trainingFlightCategory === "Dual" && row.studentPilot && <><strong>{getCrewLastNameWithInitials(row.studentPilot)}</strong><div>Student</div></>}
                {row.trainingFlightCategory === "Dual" && row.studentPilot === undefined && <>No Student</>}
                {row.trainingFlightCategory === "Solo" && row.soloSupervisor && <><strong>{getCrewLastNameWithInitials(row.soloSupervisor)}</strong><div>Supervisor</div></>}
                {row.trainingFlightCategory === "Solo" && row.soloSupervisor === undefined && <>No Solo Supervisor</>}
                {row.trainingFlightCategory === "Checkride" && row.sic && <><strong>{getCrewLastNameWithInitials(row.sic)}</strong><div>Flight Examiner</div></>}
                {row.trainingFlightCategory === "Checkride" && row.sic === undefined && <>No Flight Examiner</>}
              </>
              )
          } else {
            return passengersCellContent(info as CellContext<Flight, unknown>);
          }
        },
        enableSorting: false,
      }
    ),
    columnHelper.display({
      id: "route",
      header: "Route",
      cell: (info) => {
        return getDisplayableRouteForFlight(info.row.original, false)
      },
    }),
    columnHelper.accessor((row) => row.fplFile?.status.toLowerCase(), {
      id: "fplStatus",
      header: () => "Flight Plan",
      cell: (info) => fplFileStatusBadgeForFlight(info.row.original, selectedOrg),
      sortingFn: (a, b) => {
        const statusOrder: Flight["fplFile"]["status"][] = ["error", "ready-to-file", "filed", "delayed"];
        const aValue = a.original.fplFile?.status;
        const bValue = b.original.fplFile?.status;
        if (statusOrder.indexOf(aValue) < statusOrder.indexOf(bValue)) return -1;
        if (statusOrder.indexOf(aValue) > statusOrder.indexOf(bValue)) return 1;
        return 0;
      },
    }),
    columnHelper.accessor((row) => row.briefing?.status.toLowerCase(), {
      id: "briefingStatus",
      header: () => "Briefing",
      cell: (info) => briefingStatusBadgeForFlight(info.row.original),
      sortingFn: (a, b) => {
        const statusOrder: (FlightBriefing["status"] | "signed")[] = ["error", "not-started", "in-queue", "in-progress", "ready", "signed"];
        const aValue = a.original.briefingPilotAccept ? "signed" : a.original.briefing?.status ?? "not-started";
        const bValue = b.original.briefingPilotAccept ? "signed" : b.original.briefing?.status ?? "not-started";
        if (statusOrder.indexOf(aValue) < statusOrder.indexOf(bValue)) return -1;
        if (statusOrder.indexOf(aValue) > statusOrder.indexOf(bValue)) return 1;
        return 0;
      },
    }),
    columnHelper.display({
      id: "docs",
      header: () => "Risk",
      cell: (info) => riskAssessmentBadgeForFlight(info.row.original, selectedOrg),
    }),
    columnHelper.display({
      id: "actions",
      enableSorting: false,
      cell: (info) => (
        <div className="d-flex justify-content-end">
          <Dropdown
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Dropdown.Toggle
              size="sm"
              variant="light"
              id="actions-dropdown"
              className="me-3"
              style={{ border: "#dfdfdf", borderWidth: "1px", borderStyle: "solid" }}
            >
              Actions
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Header>Flight Plan</Dropdown.Header>
              <Dropdown.Item
                disabled={
                  info.row.original.fplFile.status === "error" ||
                  (selectedOrg && flightIsWaitingForRiskAssessment(info.row.original, selectedOrg))
                }
                onClick={() => navigate(`/flights/${info.row.original.id!}/fpl`)}
              >
                File
              </Dropdown.Item>
              <Dropdown.Item
                disabled={
                  info.row.original.fplFile.status === "error" ||
                  info.row.original.fplFile.status === "filed" ||
                  info.row.original.fplFile.status === "delayed" ||
                  (selectedOrg && flightIsWaitingForRiskAssessment(info.row.original, selectedOrg))
                }
                onClick={(e) => navigate(`/flights/${info.row.original.id!}/schedule`)}
              >
                Schedule
              </Dropdown.Item>
              <Dropdown.Item
                disabled={!(info.row.original.fplFile.status === "filed" || info.row.original.fplFile.status === "delayed")}
                onClick={() => navigate(`/flights/${info.row.original.id!}/fpl?delay`)}
              >
                Delay
              </Dropdown.Item>
              <Dropdown.Item
                disabled={!(info.row.original.fplFile.status === "filed" || info.row.original.fplFile.status === "delayed")}
                onClick={() => navigate(`/flights/${info.row.original.id!}/fpl?cancel`)}
              >
                Cancel
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Header>Briefing</Dropdown.Header>
              <Dropdown.Item
                disabled={!(info.row.original.briefing?.status === "not-started" || info.row.original.briefing?.status === "scheduled") || selectedOrg?.billing.canCreateBriefingPack === false }
                onClick={(e) => generateBriefing(info.row.original)}
              >
                Generate 
                {info.row.original.briefing?.status === "not-started" && selectedOrg?.billing.canCreateBriefingPack === false && " - No credits available"}
              </Dropdown.Item>
              <Dropdown.Item
                disabled={!(info.row.original.briefing?.status === "not-started" || info.row.original.briefing?.status === "scheduled") || selectedOrg?.billing.canCreateBriefingPack === false }
                onClick={(e) => navigate(`/flights/${info.row.original.id!}/schedule`)}
              >
                Schedule
              </Dropdown.Item>
              <Dropdown.Item
                disabled={info.row.original.briefingPilotAccept === undefined}
                onClick={(e) => downloadPdfDoc(info.row.original, "briefing-signed")}
              >
                Download Signed Briefing PDF
              </Dropdown.Item>
              <Dropdown.Item
                disabled={info.row.original.briefing?.status !== "ready"}
                onClick={(e) => downloadPdfDoc(info.row.original, "briefing")}
              >
                Download Briefing PDF
              </Dropdown.Item>
              <Dropdown.Item disabled={info.row.original.wb?.status !== "ok"} onClick={() => downloadPdfDoc(info.row.original, "wb")}>
                Download Weight & Balance
              </Dropdown.Item>
              <Dropdown.Item
                disabled={info.row.original.briefing?.status !== "ready"}
                onClick={() => downloadPdfDoc(info.row.original, "navLog")}
              >
                Download NavLog
              </Dropdown.Item>
              <Dropdown.Item
                disabled={info.row.original.fplFile.status === "error"}
                onClick={() => downloadPdfDoc(info.row.original, "icaoFpl")}
              >
                Download ICAO Plan
              </Dropdown.Item>
              <Dropdown.Item onClick={() => downloadPdfDoc(info.row.original, "genDec")}>Download GenDec</Dropdown.Item>
              {info.row.original.isCostSharing !== undefined && (
                <Dropdown.Item onClick={() => downloadPdfDoc(info.row.original, "form731")}>Download Form 731</Dropdown.Item>
              )}
              {(selectedOrg?.riskAssessment.enabled && selectedOrg.riskAssessment.flightCategories.includes(info.row.original.flightCategory)) && (
                <>
                  {info.row.original.customDocs?.riskAssessment === undefined ? (
                    <Dropdown.Item onClick={async () => await AviTracerApi.sendRiskAssessmentEmailReminder(info.row.original.id!)}>
                      Send Risk Assessment reminder
                    </Dropdown.Item>
                  ) : (
                    <Dropdown.Item
                      onClick={() =>
                        downloadCustomPdfDoc(
                          info.row.original,
                          "riskAssessment",
                          info.row.original.customDocs?.riskAssessmentSigned ? true : false
                        )
                      }
                    >
                      Download Risk Assessment
                    </Dropdown.Item>
                  )}
                </>
              )}

              {info.row.original.passengers.length > 0 && (
                <>
                  <Dropdown.Divider />
                  <Dropdown.Header>Boarding Pass</Dropdown.Header>
                  <Dropdown.Item onClick={() => navigate(`/flights/${info.row.original.id!}/boardingPasses`)}>
                    Get Boarding Passes
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => navigate(`/flights/${info.row.original.id!}/embarkationStatus`)}>
                    Embarkation Status
                  </Dropdown.Item>
                  <Dropdown.Item
                    disabled={info.row.original.checkIns === undefined}
                    onClick={() => downloadPdfDoc(info.row.original, "embarkation")}
                  >
                    Download Embarkation Report
                  </Dropdown.Item>
                </>
              )}
              <Dropdown.Divider />
              <Dropdown.Header>Flight Watch</Dropdown.Header>
              <Dropdown.Item onClick={() => navigate(`/fly/${info.row.original.id!}/watch`)}>Live Tracking</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ),
    }),
  ];

  const [showActionsOffcanvas, setShowActionsOffcanvas] = useState(false);
  const [offCanvasFlight, setOffCanvasFlight] = useState<Flight | undefined>(undefined);

  const closeOffCanvasAndAction = (action: () => void) => {
    setShowActionsOffcanvas(false);
    action();
  }

  return (
    <>
      {isGeneratingBriefing && <GeneratingBriefingModal />}

      <ListEntitiesTable
        data={data}
        columns={columns}
        defaultSortingState={[{ id: "departureDateTime", desc: true }]}
        onRowClick={(flight) => navigate(`/flights/${flight.id}`)}
        mobileCellElement={(flight, onCellClick) =>
          ListFlightsMobileCell({
            data: flight,
            selectedOrg: selectedOrg!,
            fplFileStatusBadgeForFlight,
            briefingStatusBadgeForFlight,
            riskAssessmentBadgeForFlight,
            onCellClick,
            setShowActionsOffcanvas,
            setOffCanvasFlight,
          })
        }
      />

      <Offcanvas show={showActionsOffcanvas} placement="bottom" className="offcanvas-vertical-size-xl bg-transparent" backdrop={"static"}>
        {offCanvasFlight && (
          <>
            <Offcanvas.Header className="bg-transparent">
              <div className="d-flex justify-content-center w-100">
                <Button className="rounded-circle " variant="light" size="lg" onClick={() => setShowActionsOffcanvas(false)}>
                  <FontAwesomeIcon icon={faXmark} />
                </Button>
              </div>
            </Offcanvas.Header>
            <Offcanvas.Body className="bg-white">
              <Row className="gy-2 gx-2 justify-content-center">
                <Col xs={"auto"}>
                  <span className="fs-3 fw-medium">Flight Plan</span>
                </Col>
                <Col xs={12}>
                  <div className="d-grid">
                    <Button
                      variant="dark"
                      disabled={
                        offCanvasFlight.fplFile.status === "error" ||
                        (selectedOrg && flightIsWaitingForRiskAssessment(offCanvasFlight, selectedOrg))
                      }
                      onClick={() => closeOffCanvasAndAction(() => navigate(`/flights/${offCanvasFlight.id!}/fpl`))}
                    >
                      File
                    </Button>
                  </div>
                </Col>
                <Col xs={12}>
                  <div className="d-grid">
                    <Button
                      variant="dark"
                      disabled={
                        offCanvasFlight.fplFile.status === "error" ||
                        offCanvasFlight.fplFile.status === "filed" ||
                        offCanvasFlight.fplFile.status === "delayed" ||
                        (selectedOrg && flightIsWaitingForRiskAssessment(offCanvasFlight, selectedOrg))
                      }
                      onClick={() => closeOffCanvasAndAction(() => navigate(`/flights/${offCanvasFlight.id!}/schedule`))}
                    >
                      Schedule
                    </Button>
                  </div>
                </Col>
                <Col xs={6}>
                  <div className="d-grid">
                    <Button
                      variant="dark"
                      disabled={!(offCanvasFlight.fplFile.status === "filed" || offCanvasFlight.fplFile.status === "delayed")}
                      onClick={() => closeOffCanvasAndAction(() => navigate(`/flights/${offCanvasFlight.id!}/fpl?delay`))}
                    >
                      Delay
                    </Button>
                  </div>
                </Col>
                <Col xs={6}>
                  <div className="d-grid">
                    <Button
                      variant="dark"
                      disabled={!(offCanvasFlight.fplFile.status === "filed" || offCanvasFlight.fplFile.status === "delayed")}
                      onClick={() => closeOffCanvasAndAction(() => navigate(`/flights/${offCanvasFlight.id!}/fpl?cancel`))}
                    >
                      Cancel
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row className="gy-2 gx-2 justify-content-center pt-4">
                <Col xs={"auto"}>
                  <span className="fs-3 fw-medium">Briefing</span>
                </Col>

                <Col xs={12}>
                  <div className="d-grid">
                    <Button
                      variant="dark"
                      disabled={!(offCanvasFlight.briefing?.status === "not-started" || offCanvasFlight.briefing?.status === "scheduled") || selectedOrg?.billing.canCreateBriefingPack === false }
                      onClick={() => closeOffCanvasAndAction(() => generateBriefing(offCanvasFlight))}
                    >
                      Generate
                      {offCanvasFlight.briefing?.status === "not-started" &&
                        selectedOrg?.billing.canCreateBriefingPack === false &&
                        " - No credits available"}
                    </Button>
                  </div>
                </Col>
                <Col xs={12}>
                  <div className="d-grid">
                    <Button
                      variant="dark"
                      disabled={!(offCanvasFlight.briefing?.status === "not-started" || offCanvasFlight.briefing?.status === "scheduled") || selectedOrg?.billing.canCreateBriefingPack === false }
                      onClick={() => closeOffCanvasAndAction(() => navigate(`/flights/${offCanvasFlight.id!}/schedule`))}
                    >
                      Schedule
                    </Button>
                  </div>
                </Col>
                <Col xs={6}>
                  <div className="d-grid">
                    <Button
                      variant="dark"
                      disabled={offCanvasFlight.briefingPilotAccept === undefined}
                      onClick={(e) => closeOffCanvasAndAction(() => downloadPdfDoc(offCanvasFlight, "briefing-signed"))}
                    >
                      <FontAwesomeIcon icon={faCircleDown} /> Signed Briefing
                    </Button>
                  </div>
                </Col>
                <Col xs={6}>
                  <div className="d-grid">
                    <Button
                      variant="dark"
                      disabled={offCanvasFlight.briefing?.status !== "ready"}
                      onClick={() => closeOffCanvasAndAction(() => downloadPdfDoc(offCanvasFlight, "briefing"))}
                    >
                      <FontAwesomeIcon icon={faCircleDown} /> Briefing
                    </Button>
                  </div>
                </Col>

                <Col xs={6}>
                  <div className="d-grid">
                    <Button
                      variant="dark"
                      disabled={offCanvasFlight.wb?.status !== "ok"}
                      onClick={() => closeOffCanvasAndAction(() => downloadPdfDoc(offCanvasFlight, "wb"))}
                    >
                      <FontAwesomeIcon icon={faCircleDown} /> Weight & Balance
                    </Button>
                  </div>
                </Col>

                <Col xs={6}>
                  <div className="d-grid">
                    <Button
                      variant="dark"
                      disabled={offCanvasFlight.briefing?.status !== "ready"}
                      onClick={() => closeOffCanvasAndAction(() => downloadPdfDoc(offCanvasFlight, "navLog"))}
                    >
                      <FontAwesomeIcon icon={faCircleDown} /> NavLog
                    </Button>
                  </div>
                </Col>

                <Col xs={6}>
                  <div className="d-grid">
                    <Button
                      variant="dark"
                      disabled={offCanvasFlight.fplFile.status === "error"}
                      onClick={() => closeOffCanvasAndAction(() => downloadPdfDoc(offCanvasFlight, "icaoFpl"))}
                    >
                      <FontAwesomeIcon icon={faCircleDown} /> ICAO Plan
                    </Button>
                  </div>
                </Col>

                <Col xs={6}>
                  <div className="d-grid">
                    <Button variant="dark" onClick={() => closeOffCanvasAndAction(() => downloadPdfDoc(offCanvasFlight, "genDec"))}>
                      <FontAwesomeIcon icon={faCircleDown} /> GenDec
                    </Button>
                  </div>
                </Col>

                {offCanvasFlight.isCostSharing !== undefined && (
                  <Col xs={6}>
                    <div className="d-grid">
                      <Button variant="dark" onClick={() => closeOffCanvasAndAction(() => downloadPdfDoc(offCanvasFlight, "form731"))}>
                        <FontAwesomeIcon icon={faCircleDown} /> Form 731
                      </Button>
                    </div>
                  </Col>
                )}
              </Row>

              {selectedOrg?.riskAssessment.enabled &&
                selectedOrg.riskAssessment.flightCategories.includes(offCanvasFlight.flightCategory) && (
                  <Row className="gy-2 gx-2 justify-content-center pt-4">
                    <Col xs={"auto"}>
                      <span className="fs-3 fw-medium">Risk assessment</span>
                    </Col>
                    <Col xs={12}>
                      {offCanvasFlight.customDocs?.riskAssessment === undefined ? (
                        <div className="d-grid">
                          <Button
                            variant="dark"
                            onClick={async () => await AviTracerApi.sendRiskAssessmentEmailReminder(offCanvasFlight.id!)}
                          >
                            Send Risk Assessment reminder
                          </Button>
                        </div>
                      ) : (
                        <div className="d-grid">
                          <Button
                            variant="dark"
                            onClick={() => {
                              closeOffCanvasAndAction(() => {
                                downloadCustomPdfDoc(
                                  offCanvasFlight,
                                  "riskAssessment",
                                  offCanvasFlight.customDocs?.riskAssessmentSigned ? true : false
                                );
                              });
                            }}
                          >
                            <FontAwesomeIcon icon={faCircleDown} /> Risk Assessment
                          </Button>
                        </div>
                      )}
                    </Col>
                  </Row>
                )}

              {offCanvasFlight.passengers.length > 0 && (
                <Row className="gy-2 gx-2 justify-content-center pt-4">
                  <Col xs={"auto"}>
                    <span className="fs-3 fw-medium">Boarding Pass</span>
                  </Col>

                  <Col xs={12}>
                    <div className="d-grid">
                      <Button
                        variant="dark"
                        onClick={() => closeOffCanvasAndAction(() => navigate(`/flights/${offCanvasFlight.id!}/boardingPasses`))}
                      >
                        Get Boarding Passes
                      </Button>
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="d-grid">
                      <Button
                        variant="dark"
                        onClick={() => closeOffCanvasAndAction(() => navigate(`/flights/${offCanvasFlight.id!}/embarkationStatus`))}
                      >
                        Embarkation Status
                      </Button>
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="d-grid">
                      <Button
                        variant="dark"
                        disabled={offCanvasFlight.checkIns === undefined}
                        onClick={() => closeOffCanvasAndAction(() => downloadPdfDoc(offCanvasFlight, "embarkation"))}
                      >
                        <FontAwesomeIcon icon={faCircleDown} /> Embkn. Report
                      </Button>
                    </div>
                  </Col>
                </Row>
              )}

              <Row className="gy-2 gx-2 justify-content-center pt-4">
                <Col xs={"auto"}>
                  <span className="fs-3 fw-medium">Flight Watch</span>
                </Col>

                <Col xs={12}>
                  <div className="d-grid">
                    <Button variant="dark" onClick={() => closeOffCanvasAndAction(() => navigate(`/fly/${offCanvasFlight.id!}/watch`))}>
                      Live Tracking
                    </Button>
                  </div>
                </Col>
              </Row>
            </Offcanvas.Body>
          </>
        )}
      </Offcanvas>
    </>
  );
}

interface ListFlightsMobileCellProps {
  data: Flight
  selectedOrg: Organization
  fplFileStatusBadgeForFlight: (flight: Flight, selectedOrg?: Organization) => JSX.Element
  briefingStatusBadgeForFlight: (flight: Flight) => JSX.Element
  riskAssessmentBadgeForFlight: (flight: Flight, selectedOrg?: Organization) => JSX.Element
  onCellClick: () => void
  setShowActionsOffcanvas: React.Dispatch<React.SetStateAction<boolean>>
  setOffCanvasFlight: React.Dispatch<React.SetStateAction<Flight | undefined>>
}

function ListFlightsMobileCell({data, selectedOrg, fplFileStatusBadgeForFlight, briefingStatusBadgeForFlight, riskAssessmentBadgeForFlight, onCellClick, setShowActionsOffcanvas, setOffCanvasFlight }: ListFlightsMobileCellProps){

  return (
    <>
      <Row className="align-items-center"
        onClick={(e) => {
          e.stopPropagation();
          onCellClick()
        }}
      >
        <Col xs={8}>
          <Row>
            <Col>
              {
                <>
                  <span className="fs-3">
                    <strong>{moment(data.departureDateTime).utc().format("D/M/YY")}</strong>
                    {" - "}
                    {moment(data.departureDateTime).utc().format("HH:mm")}
                  </span>
                  {data.airfieldSlots?.departure && (
                    <div className="text-success fs-6">{data.airfieldSlots?.departure.airfieldId?.toUpperCase()} Slot</div>
                  )}
                </>
              }
            </Col>
          </Row>
          <Row>
            <Col>
              {data.aircraft.registration.replace("-", "") !== data.flightId ? (
                <>
                  <strong>{data.flightId}</strong> - {data.aircraft.registration}
                </>
              ) : (
                <strong>{data.aircraft.registration}</strong>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <strong>PIC:</strong> {data.pic.lastName} 
              {" "}({data.flightCategory !== "Training" ? data.flightCategory : data.trainingFlightCategory!})
            </Col>
          </Row>
          <Row>
            <Col>
              {data.flightCategory === "Training" ? (
                <>
                  {data.trainingFlightCategory === "Dual" && data.studentPilot && <><strong>Student: </strong>{getCrewLastNameWithInitials(data.studentPilot)}</>}
                  {data.trainingFlightCategory === "Dual" && data.studentPilot === undefined && <>Student not set</>}
                  {data.trainingFlightCategory === "Solo" && data.soloSupervisor && <><strong>Supervisor: </strong>{getCrewLastNameWithInitials(data.soloSupervisor)}</>}
                  {data.trainingFlightCategory === "Solo" && data.soloSupervisor === undefined && <>Solo supervisor not set</>}
                  {data.trainingFlightCategory === "Checkride" && data.sic && <><strong>FE: </strong>{getCrewLastNameWithInitials(data.sic)}</>}
                  {data.trainingFlightCategory === "Checkride" && data.sic === undefined && <>FE not set</>}
                </>
              ) : (
                <>
                  <strong>PAX:</strong> {data.passengers.length}
                  {data.passengers.length > 0 && (
                    <>
                      {" - "}
                      {getPassengerLastNameWithInitials(data.passengers[0])}
                    </>
                  )}
                </>
              )}
            </Col>
          </Row>
          <Row>
            <Col>{getDisplayableRouteForFlight(data, true)}</Col>
          </Row>
        </Col>
        <Col xs={4}>
          <div className="d-flex justify-content-end">
            <Button
              onClick={(e) => {
                e.stopPropagation();
                setOffCanvasFlight(data);
                setShowActionsOffcanvas(true)
              }}
              variant="light"
              className="me-3"
              style={{ border: "#dfdfdf", borderWidth: "1px", borderStyle: "solid" }}
            >
              Actions
            </Button>
          </div>
        </Col>
      </Row>
      <Row className="pt-3 justify-content-center">
        <Col xs={4}>
          <center>
            <small>Flight Plan</small>
            <br />
            {fplFileStatusBadgeForFlight(data, selectedOrg)}
          </center>
        </Col>
        <Col xs={4}>
          <center>
            <small>Briefing</small>
            <br />
            {briefingStatusBadgeForFlight(data)}
          </center>
        </Col>
        <Col xs={4}>
          <center>
            <small>Risk</small>
            <br />
            {riskAssessmentBadgeForFlight(data, selectedOrg)}
          </center>
        </Col>
      </Row>
    </>
  );
}

function GeneratingBriefingModal() {
  return (
    <Modal show={true} backdrop="static" keyboard={false} centered>
      <Modal.Header>
        <Modal.Title>Requesting Briefing</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </div>
        <div className="text-center">Please do not close your browser window.</div>
        
      </Modal.Body>
    </Modal>
  );
}

export function PassengersCellContentComponent({
  textValue,
  passengers
}: { 
  textValue: string;
  passengers: Passenger[]
}) {
  const paxCount = passengers.length;
  
  if (paxCount === 0) {
    return <>{"No PAX"}</>;
  } else {
    const badge = (
      <OverlayTrigger
        placement="right"
        overlay={
          <Tooltip id="button-tooltip-2">
            <div className="p-1 px-3">
              {passengers.map((pax, index) => {
                if (index === 0) return null;
                return (
                  <div className="fs-6" key={index}>
                    {getPassengerFullName(pax)}
                  </div>
                );
              })}
            </div>
          </Tooltip>
        }
      >
        <Badge pill bg="secondary">
          +{paxCount - 1}
        </Badge>
      </OverlayTrigger>
    );
    return (
      <>
        {textValue} { (paxCount > 1) && badge}
      </>
    );
  }
}

function NoFlightsComponent() {
  return (
    <div className="d-flex flex-column align-items-center pt-5">
      <Player
        autoplay
        loop
        src="/assets/brief/lottie/no_flights.json"
        speed={1.5}
        background="transparent"
        style={{ height: "300px", width: "300px" }}
      />
      <strong>No flights in this period</strong>
    </div>
  );
}

function getDisplayableRouteForFlight(flight: Flight, oneline: boolean) {
  const route = flight.route;
  var pointNames: string[];
  const routeName = route ? route.name : "Custom";

  if (route) {
    pointNames = [route.departure.name, ...route.intermediateStops.map((s) => s.name), route.destination.name];
  } else {
    const customRoute = flight.customRoute!;
    pointNames = [
      getDisplayableTextFromFlightPlanPoint(customRoute.departure),
      ...customRoute.intermediateStops.map((s) => s.name),
      getDisplayableTextFromFlightPlanPoint(customRoute.destination),
    ];
  }
  return (
    <>
      <strong>{routeName}{oneline && <>: </>}</strong>
      {oneline ? (
        <>{pointNames.join(" - ")}</>
      ) : (
        <>
          {pointNames.map((p) => {
            return <div key={p}>{p}</div>;
          })}
        </>
      )}
    </>
  );
}