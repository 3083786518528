import { Button, Col, Offcanvas, Row } from "react-bootstrap";
import { useLoaderData, useNavigate } from "react-router-dom";
import Flight from "../../../models/flight";
import { useEffect, useState } from "react";
import FlatPicker from "react-flatpickr";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { cancelScheduleBriefingCreate, cancelScheduleFileFpl, scheduleBriefingCreate, scheduleFileFpl, getFlightWithId } from "../../../store/flightSlice";
import moment from "moment";

function FlightActionsSchedulePage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const loadedFlight = useLoaderData() as Flight;
  const stateFlight = useAppSelector((s) => getFlightWithId(s, loadedFlight.id!));

  useEffect(() => {
    if (stateFlight){
      setFlight(stateFlight);
    }
  }, [stateFlight]);

  const [flight, setFlight] = useState<Flight>(loadedFlight);

  const [show, setShow] = useState(true);

  const [isUpdatingBriefingStatus, setIsUpdatingBriefingStatus] = useState(false);
  const [isUpdatingFplFileStatus, setIsUpdatingFplFileStatus] = useState(false);

  const [updateBriefingError, setUpdateBriefingError] = useState<string | null>(null);
  const [updateFplFileError, setUpdateFplFileError] = useState<string | null>(null);

  const scheduleBriefingPressed = async () => {
    setIsUpdatingBriefingStatus(true);
    setUpdateBriefingError(null);
    try {
      await dispatch(scheduleBriefingCreate({flightId: flight.id!, scheduleTime: scheduleBriefingTime})).unwrap()
    } catch (e: any) {
      setUpdateBriefingError(e.message);
    } finally {
      setIsUpdatingBriefingStatus(false);
    }
  }

  const cancelScheduleBriefingPressed = async () => {
    setIsUpdatingBriefingStatus(true);
    setUpdateBriefingError(null);
    try {
      await dispatch(cancelScheduleBriefingCreate(flight.id!)).unwrap()
    } catch (e: any) {
      setUpdateBriefingError(e.message);
    } finally {
      setIsUpdatingBriefingStatus(false);
    }
  }

  const scheduleFplFilePressed = async () => {
    setIsUpdatingFplFileStatus(true);
    setUpdateFplFileError(null);
    try {
      await dispatch(scheduleFileFpl({flightId: flight.id!, scheduleTime: scheduleFplFileTime})).unwrap()
    } catch (e: any) {
      setUpdateFplFileError(e.message);
    } finally {
      setIsUpdatingFplFileStatus(false);
    }
  }

  const cancelScheduleFileFplPressed = async () => {
    setIsUpdatingFplFileStatus(true);
    setUpdateFplFileError(null);
    try {
      await dispatch(cancelScheduleFileFpl(flight.id!)).unwrap()
    } catch (e: any) {
      setUpdateFplFileError(e.message);
    } finally {
      setIsUpdatingFplFileStatus(false);
    }
  }

  const goBack = () => {
    navigate("/flights");
  };
 
  const roundedTimeToNext5Mins = (time: Date) => {
    return moment(time).add(5 - moment(time).minute() % 5, 'minutes').set('second', 0).toDate();
  }

  const utcOffset = moment().utcOffset();

  const [scheduleFplFileTime, setScheduleFplFileTime] = useState<Date>( () => {
    var defaultTime = moment(flight.departureDateTime).subtract(1, "hour").toDate();

    const minDate = moment().add(5,"minutes").toDate();
    const maxDate = moment(flight.departureDateTime).subtract(10, "minutes").toDate();

    if (moment(defaultTime).isBefore(minDate)) {
      defaultTime = minDate;
    }
    if (moment(defaultTime).isAfter(maxDate)) {
      defaultTime = maxDate;
    }

    
    defaultTime = roundedTimeToNext5Mins(defaultTime)
    
    return (flight.fplFile?.status === "scheduled" ? moment(flight.fplFile.scheduleTime) : moment(defaultTime)).toDate();
  });

  const [scheduleBriefingTime, setScheduleBriefingTime] = useState<Date>( () => {
    var defaultTime = moment(flight.departureDateTime).subtract(1, "hour").toDate();

    const minDate = moment().add(5,"minutes").toDate();
    const maxDate = moment(flight.departureDateTime).subtract(15, "minutes").toDate();

    
    if (moment(defaultTime).isBefore(minDate)) {
      defaultTime = minDate;
    }
    if (moment(defaultTime).isAfter(maxDate)) {
      defaultTime = maxDate;
    }

    defaultTime = roundedTimeToNext5Mins(defaultTime)
    
    return (flight.briefing?.status === "scheduled" ? moment(flight.briefing.scheduleTime) : moment(defaultTime)).toDate();
  });

  const [scheduleNotAvailableAnyMore, setScheduleNotAvailableAnyMore] = useState(false);
  useEffect(() => {
    if (moment(flight.departureDateTime).subtract(30, "minutes").isBefore(moment())) {
      setScheduleNotAvailableAnyMore(true);
    } else {
      setScheduleNotAvailableAnyMore(false);
    }
  }, [scheduleFplFileTime, scheduleBriefingTime, flight.departureDateTime]);

  const fplFilePickerTime = moment(scheduleFplFileTime).subtract(utcOffset, 'minutes').toDate()
  const briefingPickerTime = moment(scheduleBriefingTime).subtract(utcOffset, 'minutes').toDate()
  
  return (
    <Offcanvas backdrop="static" show={show} onExited={goBack} placement={"end"} className="offcanvas-size-lg">
      <Offcanvas.Header closeButton onHide={() => setShow(false)}>
        <Offcanvas.Title>
          {flight.flightId} - {moment(flight.departureDateTime).utc().format("DD/MM HH:mm")} - {flight.pic.flightPlanPicDetails}
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Row className="pt-2 justify-content-between">
          <h2 className="pb-3">Schedule File Flight Plan (FPL)</h2>
          <Col xs={7}>
            <FlatPicker
              disabled={flight.fplFile?.status !== "ready-to-file"}
              value={fplFilePickerTime}
              options={{
                enableTime: true,
                noCalendar: false,
                time_24hr: true,
                static: true,
                minDate: flight.fplFile.status !== "scheduled" ? roundedTimeToNext5Mins(moment().subtract(utcOffset, "minutes").toDate())  : undefined,
                maxDate: flight.fplFile.status !== "scheduled" ? roundedTimeToNext5Mins(moment(flight.departureDateTime).subtract(utcOffset, "minutes").subtract(10, "minutes").toDate())  : undefined,
              }}
              onClose={(s) => {
                const newUtcDate = moment(s[0]).add(utcOffset, "minutes").toDate();
                setScheduleFplFileTime(
                  new Date(
                    newUtcDate.getFullYear(),
                    newUtcDate.getMonth(),
                    newUtcDate.getDate(),
                    newUtcDate.getHours(),
                    newUtcDate.getMinutes()
                  )
                );
              }}
              className="form-control bg-white"
              render={({ value, ...props }, ref) => {
                return (
                  <div className={"input-container rounded-lg"} style={{ backgroundColor: "#F7F7F7" }}>
                    <input ref={ref} disabled={flight.fplFile?.status !== "ready-to-file"} className="bg-transparent" readOnly />
                    <label className={value ? "filled" : ""}>{"Schedule Time (UTC)"}</label>
                  </div>
                );
              }}
            />
          </Col>

          <Col xs={4}>
            <div className="d-grid h-100">
              {flight.fplFile?.status === "scheduled" && (
                <Button disabled={isUpdatingFplFileStatus} variant="danger" onClick={cancelScheduleFileFplPressed}>
                  {isUpdatingFplFileStatus ? "Cancelling..." : "Cancel"}
                </Button>
              )}
              {flight.fplFile?.status === "ready-to-file" && (
                <Button disabled={isUpdatingFplFileStatus || scheduleNotAvailableAnyMore} variant="dark" onClick={scheduleFplFilePressed}>
                  {isUpdatingFplFileStatus ? "Scheduling..." : "Schedule"}
                </Button>
              )}
              {(flight.fplFile?.status === "filed" || flight.fplFile?.status === "delayed") && (
                <Button disabled={true} variant="secondary">
                  Already Filed
                </Button>
              )}
              {(flight.fplFile?.status === "not-filed" || flight.fplFile?.status === "error") && (
                <Button disabled={true} variant="secondary">
                  Not Available
                </Button>
              )}
            </div>
          </Col>
          {updateFplFileError && <small className="text-danger">{updateFplFileError}</small>}
          {scheduleNotAvailableAnyMore && <small className="text-danger">Cannot schedule flight plan file 25 minutes before departure. Please use the File FPL option.</small>}
          <small className="pt-2">
            The flight plan (FPL) will be filed automatically at the scheduled time. The PIC will receive an email confirmation with the
            filed FPL.
          </small>
        </Row>

        <Row className="justify-content-between pt-5">
          <h2 className="pb-3">Schedule Briefing Pack Generation</h2>
          <Col xs={7}>
            <FlatPicker
              disabled={flight.briefing?.status !== "not-started"}
              value={ briefingPickerTime }
              options={{
                enableTime: true,
                noCalendar: false,
                time_24hr: true,
                static: true,
                minDate: flight.briefing?.status !== "scheduled" ? roundedTimeToNext5Mins(moment().subtract(utcOffset, "minutes").toDate()) : undefined,
                maxDate: flight.briefing?.status !== "scheduled" ? roundedTimeToNext5Mins(moment(flight.departureDateTime).subtract(utcOffset, "minutes").subtract(15, "minutes").toDate()) : undefined,
              }}
              onClose={(s) => {
                const newUtcDate = moment(s[0]).add(utcOffset, "minutes").toDate();
                setScheduleBriefingTime(
                  new Date(
                    newUtcDate.getFullYear(),
                    newUtcDate.getMonth(),
                    newUtcDate.getDate(),
                    newUtcDate.getHours(),
                    newUtcDate.getMinutes()
                  )
                );
              }}
              className="form-control bg-white"
              render={({ value, ...props }, ref) => {
                return (
                  <div className={"input-container rounded-lg"} style={{ backgroundColor: "#F7F7F7" }}>
                    <input ref={ref} disabled={flight.briefing?.status !== "not-started"} className="bg-transparent" readOnly />
                    <label className={value ? "filled" : ""}>{"Schedule Time (UTC)"}</label>
                  </div>
                );
              }}
            />
          </Col>
          <Col xs={4}>
            <div className="d-grid h-100">
              {flight.briefing?.status === "scheduled" && (
                <Button disabled={isUpdatingBriefingStatus} variant="danger" onClick={cancelScheduleBriefingPressed}>
                  {isUpdatingBriefingStatus ? "Cancelling..." : "Cancel"}
                </Button>
              )}
              {flight.briefing?.status === "not-started" && (
                <Button disabled={isUpdatingBriefingStatus || scheduleNotAvailableAnyMore} variant="dark" onClick={scheduleBriefingPressed}>
                  {isUpdatingBriefingStatus ? "Scheduling..." : "Schedule"}
                </Button>
              )}
              {(flight.briefing?.status === "in-progress" ||
                flight.briefing?.status === "in-queue" ||
                flight.briefing?.status === "ready") && (
                <Button disabled={true} variant="secondary">
                  Already Generated
                </Button>
              )}
              {(flight.briefing?.status === "error") && (
                <Button disabled={true} variant="secondary">
                  Not Available
                </Button>
              )}
            </div>
          </Col>
          {updateBriefingError && <small className="text-danger">{updateBriefingError}</small>}
          {scheduleNotAvailableAnyMore && <small className="text-danger">Cannot schedule briefing pack creation 25 minutes before departure. Please use the Generate option.</small>}
          <small className="pt-2">
            The briefing pack generation will begin at the scheduled time. The PIC will receive an email notification when it is ready.
          </small>
        </Row>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default FlightActionsSchedulePage;