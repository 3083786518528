import { Control, FieldValues, useController } from "react-hook-form";
import { EntityDetailsDisplayInfoFieldAirfieldSlot } from "../EntityDetailsDisplayInfo";
import { database } from "../../../firebase";
import { onValue, ref } from "firebase/database";
import { FlightAirfieldSlot } from "../../../models/flight";
import { useEffect, useState } from "react";
import moment from "moment";
import { AirfieldSlotStored } from "../../../models/airfieldSlots";
import { Alert } from "react-bootstrap";

interface EntityFlightAirfieldSlotComponentProps<Entity extends FieldValues> {
    displayInfoInput: EntityDetailsDisplayInfoFieldAirfieldSlot<Entity>;
    control: Control<Entity, any>;
}

function EntityFlightAirfieldSlotComponent<Entity extends FieldValues>({displayInfoInput, control}: EntityFlightAirfieldSlotComponentProps<Entity>){
  const {
    field: { value },
  } = useController({
    name: displayInfoInput.key,
    control,
  });

  const airfieldSlot = value as FlightAirfieldSlot;

  const [liveAirfieldSlotStored, setLiveAirfieldSlotStored] = useState<AirfieldSlotStored | undefined>(undefined);
  useEffect(() => {
    if (airfieldSlot) {
      const dateStr = moment(airfieldSlot.startDateTimeEpoch * 1000).utc(true).format("DD-MM-YYYY");
      const queryRef = ref(database, `/airfield/${airfieldSlot.airfieldId}/slots/${dateStr}/${airfieldSlot.bookedSlotKey}`);
      return onValue(queryRef,(snapshot) => {
          if (snapshot.exists()) {
            setLiveAirfieldSlotStored(snapshot.val() as AirfieldSlotStored);
          } else {
            setLiveAirfieldSlotStored(undefined);
          }
        }
      );
    } else {
      setLiveAirfieldSlotStored(undefined);
    }
  }, [airfieldSlot]);

  // const [currentTime, setCurrentTime] = useState(new Date());
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setCurrentTime(new Date());
  //   }, 5000);
  //   return () => clearInterval(interval);
  // }, []);

  return (
    <>
      {liveAirfieldSlotStored && (
          <Alert variant="success">
            Confirmed <strong>{liveAirfieldSlotStored.slotType}</strong> flight slot at{" "}
            <strong>
              {moment(airfieldSlot.startDateTimeEpoch * 1000)
                .utc(false)
                .format("HH:mm")}
              Z
            </strong> for <strong>{airfieldSlot.airfieldId.toUpperCase()}</strong>.<div><small>You need to book a new slot to change the departure time.</small></div>
          </Alert>
        )}
      {/* {liveAirfieldSlotStored &&
        liveAirfieldSlotStored.status === "pending_confirmation" &&
        liveAirfieldSlotStored.by.orgId === selectedOrg!.id &&
        liveAirfieldSlotStored.untilDateTimeEpoch * 1000 > moment(currentTime).toDate().getTime() && (
          <Alert variant="warning">
            Pending flight creation for <strong>{liveAirfieldSlotStored.slotType}</strong> slot on{" "}
            <strong>
              {moment(airfieldSlot.startDateTimeEpoch * 1000)
                .utc(false)
                .format("HH:mm")}
            </strong>
            .
            <div>
              Please create this flight before{" "}
              <strong>
                {moment(liveAirfieldSlotStored.untilDateTimeEpoch * 1000)
                  .utc(false)
                  .format("HH:mm")}
              </strong>{" "}
              to not lose your slot.
            </div>
          </Alert>
        )}
      {liveAirfieldSlotStored &&
        liveAirfieldSlotStored.status === "pending_confirmation" &&
        liveAirfieldSlotStored.by.orgId === selectedOrg!.id &&
        liveAirfieldSlotStored.untilDateTimeEpoch * 1000 <= moment(currentTime).toDate().getTime() && (
          <Alert variant="danger">
            Your <strong>{liveAirfieldSlotStored.slotType}</strong> slot on{" "}
            <strong>
              {moment(airfieldSlot.startDateTimeEpoch * 1000)
                .utc(false)
                .format("HH:mm")}
            </strong>{" "}
            did expire at{" "}
            {moment(liveAirfieldSlotStored.untilDateTimeEpoch * 1000)
              .utc(false)
              .format("HH:mm")}{" "}
            but has not been claimed by a different user yet.
          </Alert>
        )}
      {liveAirfieldSlotStored &&
        ((liveAirfieldSlotStored.status === "pending_confirmation" && liveAirfieldSlotStored.by.orgId !== selectedOrg!.id) ||
          (liveAirfieldSlotStored.status === "booked" && liveAirfieldSlotStored.bookedBy.orgId !== selectedOrg!.id)) && (
          <Alert variant="danger">A different user has claimed this slot. Please book a different one.</Alert>
        )} */}
    </>
  );
}

export default EntityFlightAirfieldSlotComponent;