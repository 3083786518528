import { useEffect, useState } from "react";

function useCurrentTime(everyMsInterval: number = 5000) {
    const [currentTime, setCurrentTime] = useState(new Date())

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(new Date())
        }, everyMsInterval);
        return () => clearInterval(interval)
    }, [everyMsInterval])

    return currentTime
}

export default useCurrentTime;