import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectedOrganization, getOrganizations } from "../../store/organizationsSlice";
import LoadingComponent from "../../components/LoadingComponent";
import { Button, Col, Container, Modal, Offcanvas, Row } from "react-bootstrap";
import SideMenuOrganizationComponent from "../sidemenu/SideMenuOrganizationComponent";
import SelectOrganizationModal from "../../pages/organization/selectOrganization/SelectOrganizationModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faUser, faPowerOff } from "@fortawesome/free-solid-svg-icons";
import { auth } from "../../firebase";
import { Organization } from "../../models/organization";

function FlyLayout() {
  const navigate = useNavigate();
  const selectedOrg = useAppSelector(selectedOrganization);
  const dispatch = useAppDispatch();

  const { allOrganizations: allOrgs, userHasNoOrganizations } = useAppSelector((state) => state.organizations);

  const airfieldSlots = useMemo(() => {
    return selectedOrg?.airfieldSlots ?? [];
  }, [selectedOrg]);

  const [showMobileOffcanvas, setShowMobileOffcanvas] = useState(false)
  const [showSelectOrganizationModal, setShowSelectOrganizationModal] = useState(false)
  const [showLogoutModal, setShowLogoutModal] = useState(false)

  useEffect(() => {
    if (userHasNoOrganizations) {
      navigate("/organization/create");
    } else if (allOrgs.length === 0) {
      dispatch(getOrganizations());
    } 
  }, [selectedOrg, allOrgs, dispatch, userHasNoOrganizations, navigate]);

  return (
    <>
      {selectedOrg ? (
        <>
          <Container className="fixed-top  py-2">
            <div className="d-flex justify-content-end">
              <Button className="" variant="outline-dark" onClick={() => setShowMobileOffcanvas(true)}>
                <FontAwesomeIcon icon={faBars} color="black" size={"lg"} />
              </Button>
            </div>
          </Container>
          <Outlet />

          <Offcanvas show={showMobileOffcanvas && !showLogoutModal} placement="end" onHide={()=>setShowMobileOffcanvas(false)}>
            <Row className="h-100 px-2 bg-light">
              <Col xs={12} className="d-flex flex-column justify-content-between">
                <div style={{ paddingTop: "30px" }}>
                  <SideMenuOrganizationComponent
                    presentSelectModal={() => {
                      setShowMobileOffcanvas(false);
                      setShowSelectOrganizationModal(true);
                    }}
                  />
                  <NavLinksMenu setShowMobileOffcanvas={setShowMobileOffcanvas} airfieldSlots={airfieldSlots} />
                </div>
                <AccountComponent selectedOrg={selectedOrg} setShowLogoutModal={setShowLogoutModal} />
              </Col>
            </Row>
          </Offcanvas>
          {showSelectOrganizationModal && <SelectOrganizationModal hideModal={() => setShowSelectOrganizationModal(false)} />}
          {showLogoutModal && (
            <Modal show={showLogoutModal}>
              <Modal.Body>
                <Modal.Title>Are you sure you want to logout?</Modal.Title>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="outline-dark" onClick={() => setShowLogoutModal(false)}>
                  Cancel
                </Button>
                <Button variant="danger" href="/auth/logout" >
                  Logout
                </Button>
              </Modal.Footer>
            </Modal>
          )}
        </>
      ) : (
        <LoadingComponent text="Loading your organizations..." />
      )}
    </>
  );
}

export default FlyLayout;

interface NavLinksMenuProps {
  setShowMobileOffcanvas?: React.Dispatch<React.SetStateAction<boolean>>;
  airfieldSlots: Organization["airfieldSlots"];
}

function NavLinksMenu({ setShowMobileOffcanvas, airfieldSlots }: NavLinksMenuProps) {
  return (
    <div className="sticky-top">
      <nav className="nav flex-column nav-pills pt-4">
        <NavLink to="/fly" className="nav-link" end onClick={() => setShowMobileOffcanvas && setShowMobileOffcanvas(false)}>
          Flights
        </NavLink>
        {airfieldSlots.map((slot) => (
          <NavLink key={slot.airfieldId} to={`/fly/airfield/${slot.airfieldId}/slots`} className="nav-link" onClick={() => setShowMobileOffcanvas && setShowMobileOffcanvas(false)}>
            {slot.airfieldName} Slots
          </NavLink>
        ))}
      </nav>
    </div>
  );
}

interface AccountComponentProps {
  selectedOrg: Organization;
  setShowLogoutModal: React.Dispatch<React.SetStateAction<boolean>>;
}

function AccountComponent({ selectedOrg, setShowLogoutModal }: AccountComponentProps) {
  const navigate = useNavigate();


  return (
    <div>
      <div className="pb-3">
        {selectedOrg.role === "admin" && (
          <div className="d-grid pb-3">
            <Button variant="outline-dark" onClick={() => navigate("/")}>
              Go to Admin
            </Button>
          </div>
        )}
        <div className="bg-white border rounded h-100 w-100" onClick={() => setShowLogoutModal(true)} style={{ cursor: "pointer" }}>
          <div className="d-flex align-items-center my-1">
            <div className="px-2 ">
              <FontAwesomeIcon icon={faUser} color="black" size={"lg"} />
            </div>
            <div className="overflow-hidden flex-fill">
              <div className="fw-medium py-2">{auth.currentUser?.email}</div>
            </div>
            <div className="ms-1 me-3">
              <FontAwesomeIcon icon={faPowerOff} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}