import { useLoaderData, useNavigate } from "react-router-dom";
import { AviTracerApi } from "../../../avitracerApi";
import Flight, { FlightFplFile, flightIsWaitingForRiskAssessment } from "../../../models/flight";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { cancelFpl, delayFpl, fileFpl } from "../../../store/flightSlice";
import BaseFplPage from "../BaseFplPage";
import { selectedOrganization } from "../../../store/organizationsSlice";

function FlightFplPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const loadedFlight = useLoaderData() as Flight;

  const selectedOrg = useAppSelector(selectedOrganization);
  
  const getPdfDoc = async () => {
    const pdfData = await AviTracerApi.getPdfDoc(loadedFlight.id!, "icaoFpl");
    return pdfData;
  };

  const fileFplUsingAviTracer = async () => {
    try {
      await dispatch(fileFpl(loadedFlight.id!)).unwrap();
    } catch (e: any) {
      alert(e.message);
    }
  };

  const refileFpl = async () => {
    try {
      await AviTracerApi.refileFpl(loadedFlight.id!);
    } catch (e: any) {
      alert(e.message);
    }
  };

  const chgFpl = async (f15: string, f18: string) => {
    try {
      await AviTracerApi.chgFpl(loadedFlight.id!, f15, f18);
    } catch (e: any) {
      alert(e);
    }
  }

  const cancelFiledFpl = async () => {
    try {
      await dispatch(cancelFpl(loadedFlight.id!)).unwrap();
    } catch (e: any) {
      alert(e.message);
    }
  };

  const delayFiledFpl = async (newTime: Date) => {
    try {
      const params = { flightId: loadedFlight.id!, newTime: newTime };

      await dispatch(delayFpl(params)).unwrap();
    } catch (e: any) {
      alert(e.message);
    }
  };

  const goBack = () => {
    navigate("/flights");
  };

  const goToSchedule = () => {
    navigate(`/flights/${loadedFlight.id}/schedule`);
  }

  const getFplFileAfterRiskAssessmentCheck = () => {
    if (selectedOrg && flightIsWaitingForRiskAssessment(loadedFlight, selectedOrg)){
      return {
        status: "error",
        error: "Risk assessment not ready.",
      } as FlightFplFile
    }else if (selectedOrg && loadedFlight.customDocs?.riskAssessment?.acceptanceStatus === false){
      return {
        status: "error",
        error: "Risk assessment rejected.",
      } as FlightFplFile
    }
    return loadedFlight.fplFile
  }
  
  return (
    <BaseFplPage
      data={{
        fplFile: getFplFileAfterRiskAssessmentCheck(),
        flightPlanPicDetails: loadedFlight.pic.flightPlanPicDetails,
        departureDateTime: new Date(loadedFlight.departureDateTime),
        flightId: loadedFlight.flightId,
      }}
      getPdfDoc={getPdfDoc}
      fileFplUsingAviTracerAction={fileFplUsingAviTracer}
      refileFplAction={refileFpl}
      chgFplAction={chgFpl}
      cancelFiledFplAction={cancelFiledFpl}
      delayFiledFplAction={delayFiledFpl}
      goBack={goBack}
      goToSchedule={goToSchedule}
    />
  );
}

export default FlightFplPage