import { Modal, Form, Button, Container, Row, Col, Offcanvas, Toast, ToastContainer } from "react-bootstrap"
import { EntityDetailsDisplayInfo, EntityDetailsDisplayInfoField, EntityDetailsDisplayInfoFieldPassengersLeg, EntityDetailsDisplayInfoFieldRoute, EntityDetailsDisplayInfoSection } from "./EntityDetailsDisplayInfo"
import { useEffect, useState } from "react"
import EntityInputBox from "./EntityInputBox"
import { Identifiable, implementsCanEditSharedValues } from "../../models/identifiable"
import "../../layouts/sidemenu/SideMenuLayout.css"
import "../../css/bootstrap-custom-sizes.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBoxArchive, faTrash } from '@fortawesome/free-solid-svg-icons';
import EntityDatePicker from "./EntityDatePicker"
import EntityIntermediateStops from "./EntityIntermediateStops"
import EntityAircraftPicker from "./flight/EntityAircraftPicker"
import EntityRoutePicker from "./flight/EntityRoutePicker"
import EntityCrewPicker from "./flight/EntityCrewPicker"
import EntityDropdown from "./flight/EntityDropdown"
import EntityDropdownWithInput from "./flight/EntityDropdownWithInput"
import EntityPassengersComponent from "./flight/EntityPassengersComponent"
import EntityTextAreaBox from "./EntityTextAreaBox"
import EntityDateTimePicker from "./EntityDateTimePicker"
import EntityAircraftSarComponent from "./flight/EntityAircraftSarComponent"
import EntityBooleanToggle from "./EntityBooleanToggle"
import { Control, FieldValues, Path, useForm, UseFormResetField, UseFormSetFocus, UseFormSetValue } from "react-hook-form";
import Flight, { FlightFplFile } from "../../models/flight"
import EntityAftnAddressesComponent from "./flight/EntityAftnAddressesComponent"
import EntityFlightIdComponent from "./flight/EntityFlightIdComponent"
import { Outlet, useNavigate, useOutletContext } from "react-router-dom"
import EntityFlightWbComponent from "./flight/EntityFlightWbComponent"
import EntityFuelOnBoardComponent from "./flight/EntityFuelOnBoardComponent"
import { discardBriefing } from "../../store/flightSlice"
import { useAppDispatch } from "../../store/hooks"
import EntityPobComponent from "./flight/EntityPobComponent"
import EntityTimeWithAutoSetComponent from "./flight/EntityTimeWithAutoSetComponent"
import moment from "moment"
import { ListFlightsContext } from "../../pages/organization/flights/ListFlighsPage"
import EntityFlightPlanPointPicker from "./flight/EntityFlightPlanPointPicker"
import EntityCustomRouteComponent from "./flight/EntityCustomRouteComponent"
import FlightPlan, { FlightPlanPoint, getRoutePointFromFlightPlanPoint } from "../../models/flightPlan"
import EntityPassengersLegComponent, { EntityPassengersLegComponentProps } from "./flight/EntityPassengersLegComponent"
import EntitySignatureComponent from "./flight/EntitySignatureComponent"
import ToolsFlight from "../../models/toolsFlight"
import EntityAircraftWbProfilePicker from "./flight/EntityAircraftWbProfilePicker"
import EntityFlightAirfieldSlotComponent from "./flight/EntityFlightAirfieldSlotComponent"

interface EntityDetailsPageProps<Entity extends FieldValues> {
  entity: Entity;
  handleClose: () => void;
  displayInfo: EntityDetailsDisplayInfo<Entity>;
  dispatchSaveEntity: (entity: Entity) => Promise<Entity>;
  dispatchDeleteEntity?: (entity: Entity) => Promise<void>
}
  
  
function EntityDetailsPage<Entity extends Identifiable>({entity, handleClose, displayInfo, dispatchSaveEntity, dispatchDeleteEntity}: EntityDetailsPageProps<Entity>){
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const outletContext = useOutletContext()
  
  const { control, handleSubmit, setValue, watch, formState:{isSubmitting, dirtyFields}, resetField, setFocus, trigger } = useForm<Entity>({values: entity});

  const [lockedFlightReason, setLockedFlightReason] = useState<"fplFile" | "briefing" | "scheduled" | undefined>(undefined);
  const [showDiscardFlightOffCanvas, setShowDiscardFlightOffCanvas] = useState(false);
  const [isDiscardingFlightBriefing, setIsDiscardingFlightBriefing] = useState(false);

  const edittedEntity = watch();

  const [activeInfoSection, setActiveInfoSection] = useState(displayInfo.sections[0])

  const [modalContent, setModalContent] = useState<JSX.Element | null>(null)
  const [showCloseInModalContent, setShowCloseInModalContent] = useState<boolean>(true)
  const [sideAlertContent, setSideAlertContent] = useState<JSX.Element | null>(null)

  const [errorMessage, setErrorMessage] = useState<string | undefined>()

  const handleSubmitAfterValidation = async (data: any) => {
    if (displayInfo.entityName === "Flight Plan"){
      if (!data.aftnAddresses || (data.aftnAddresses as string[]).filter((s) => s.length > 0).length === 0){
        setErrorMessage("AFTN addresses are required")
        setFocus("aftnAddresses" as Path<Entity>)
        return
      }      
    }else if (displayInfo.entityName === "Flight" && (data as Flight).customRoute){
      const aftns = (data as Flight).customRoute?.aftnAddresses
      if (!aftns || (aftns as string[]).filter((s) => s.length > 0).length === 0){
        setErrorMessage("AFTN addresses are required")
        setFocus("customRoute.aftnAddresses" as Path<Entity>)
        return
      }  
    }

    var savedEntityId: string
    try {
      const savedEntity = await dispatchSaveEntity(data);
      savedEntityId = savedEntity.id!
    }catch(e: any){
      setErrorMessage(e.message);
      return;
    }
    
    if (displayInfo.entityName === "Flight" || displayInfo.entityName === "Flight Plan"){
      const flight = data as Flight | FlightPlan
      const {setSelectedTab} = outletContext as ListFlightsContext
      if (moment(flight.departureDateTime).utc().isSame(moment().utc(),"day")){
        setSelectedTab("today")
      }else if (moment(flight.departureDateTime).utc().isSame(moment().utc().add(1,"day"),"day")){
        setSelectedTab("tomorrow")
      }else if (moment(flight.departureDateTime).utc().isAfter(moment().utc().add(1,"day"),"day")){
        setSelectedTab("upcoming")
      }else{
        setSelectedTab("archived")
      }
    }
    
    if (displayInfo.entityName === "Flight Plan"){
      navigate(`/fpls/${savedEntityId}/fpl`)
    }else if (displayInfo.entityName !== "Quick Flight Plan"){
      handleClose();
    }
  };

  const handleDelete = async () => {
    try {
      if (edittedEntity.id) {
        await dispatchDeleteEntity!(edittedEntity);
      }
    }catch(e: any){
      setErrorMessage(e.message);
      return;
    }

    handleClose();
  }; 

  const shouldDisplaySections = displayInfo.sections.filter((s) => s.shouldDisplay ? s.shouldDisplay(edittedEntity) : true)

  //Lock all fields if Flight and isLockedFlight
  shouldDisplaySections.forEach((s) => {
    var lockedSection = false
    if (implementsCanEditSharedValues(entity)){
      lockedSection = !entity.canEditSharedValues && (s.containsSharedValues ?? false)
    }
    s.inputs.forEach((i) => {
      i.disabled = i.disabled || lockedFlightReason !== undefined || lockedSection
    })
  })

  const handleClickActiveSection = (newSection: EntityDetailsDisplayInfoSection<Entity>) => {
    document.getElementById("scrollableFormRow")!.scrollTo({ top: document.getElementById(newSection.sectionId)!.offsetTop - 40, behavior: "smooth" });
  }

  const handleScrollInFormRow = () => {
    const scrollableFormRow = document.getElementById("scrollableFormRow") as HTMLElement
    const sections = document.querySelectorAll(".scrollableFormRowSection") as NodeListOf<HTMLElement>;
    sections.forEach(section => {
      if (scrollableFormRow.scrollTop >= section.offsetTop - 41) {
        setActiveInfoSection(displayInfo.sections.find((s) => s.sectionId === section.id)!);
      }
    });

  }

  useEffect(() => {
    document.getElementById("scrollableFormRow")?.addEventListener("scroll", handleScrollInFormRow)
    return () => document.getElementById("scrollableFormRow")?.removeEventListener("scroll", handleScrollInFormRow)
  })

  function getSpecificInfoField(infoField: EntityDetailsDisplayInfoField<Entity>) {
    if (infoField.type === "text") {
      if (infoField.multiline) {
        return (
          <EntityTextAreaBox
            displayInfoInput={infoField}
            control={control}
          />
        );
      } else {
        return (
          <EntityInputBox displayInfoInput={infoField} control={control} />
        );
      }
    } else if (infoField.type === "number") {
      return (
        <EntityInputBox
          displayInfoInput={infoField}
          control={control}
        />
      );
    } else if (infoField.type === "time-autoset") {
      return (
        <EntityTimeWithAutoSetComponent
          displayInfoInput={infoField}
          control={control}
          edittedEntity={edittedEntity}
        />
      );
      
    } else if (infoField.type === "boolean") {
      return (
        <EntityBooleanToggle
          displayInfoInput={infoField}
          control={control}
        />
      );
    } else if (infoField.type === "date") {
      return (
        <EntityDatePicker
          displayInfoInput={infoField}
          control={control}
        />
      );
    } else if (infoField.type === "dateTime") {
      return (
        <EntityDateTimePicker
          displayInfoInput={infoField}
          control={control}
        />
      );
    }else if (infoField.type === "intermediateStop") {
      return (
        <EntityIntermediateStops
          edittedEntity={edittedEntity}
          setSideAlertContent={setSideAlertContent}
          displayInfoInput={infoField}
          control={control}
        />
      );
    } else if (infoField.type === "aircraft") {
      return (
        <EntityAircraftPicker
          displayInfoInput={infoField}
          setModalContent={setModalContent}
          control={control}
          setValue={setValue as unknown as UseFormSetValue<Flight>}
        />
      );
    } else if (infoField.type === "aircraftWbProfileName") {
      return (
        <EntityAircraftWbProfilePicker
          displayInfoInput={infoField}
          setModalContent={setModalContent}
          control={control}
        />
      );
    } else if (infoField.type === "aircraftSar") {
      return (
        <EntityAircraftSarComponent
          displayInfoInput={infoField}
          control={control}
          setModalContent={setModalContent}
        />
      );
    } else if (infoField.type === "route") {
      return (
        <EntityRoutePicker
          displayInfoInput={infoField as unknown as EntityDetailsDisplayInfoFieldRoute<Flight>}
          setModalContent={setModalContent}
          control={control as unknown as Control<Flight, any>}
          setValue={setValue as unknown as UseFormSetValue<Flight>}
        />
      );
    } else if (infoField.type === "passengersLeg") {
      var routePart: EntityPassengersLegComponentProps<Entity>["route"]
      const flight: Flight = edittedEntity as unknown as Flight
      if (flight.customRoute){
        routePart = {
          departure: getRoutePointFromFlightPlanPoint(flight.customRoute.departure),
          destination: getRoutePointFromFlightPlanPoint(flight.customRoute.destination),
          intermediateStops: flight.customRoute.intermediateStops ?? []
        }
      }else{
        routePart = flight.route!
      }
      return (
        <EntityPassengersLegComponent
          displayInfoInput={infoField  as unknown as EntityDetailsDisplayInfoFieldPassengersLeg<Flight>}
          control={control as unknown as Control<Flight, any>}
          setModalContent={setModalContent}
          setValue={setValue as unknown as UseFormSetValue<Flight>}
          resetField={resetField as unknown as UseFormResetField<Flight>}
          setFocus={setFocus as unknown as UseFormSetFocus<Flight>}
          aircraft={flight.aircraft}
          flightDepartureDateTime={flight.departureDateTime}
          route={routePart}
          passengersLegValue={flight.passengersLeg}
        />
      );
    } else if (infoField.type === "crew") {
      return (
        <EntityCrewPicker
          control={control}
          displayInfoInput={infoField}
          setModalContent={setModalContent}
        />
      );
    } else if (infoField.type === "dropdown") {
      return (
        <EntityDropdown
          displayInfoInput={infoField}
          control={control}
        />
      );
    } else if (infoField.type === "dropdownInput") {
      return (
        <EntityDropdownWithInput
          displayInfoInput={infoField}
          control={control}
        />
      );
    } else if (infoField.type === "passengers") {
      return (
        <EntityPassengersComponent
          displayInfoInput={infoField}
          setModalContent={setModalContent}
          setShowCloseInModalContent={setShowCloseInModalContent}
          control={control}
        />
      );
    } else if (infoField.type === "aftnAddresses"){
      return (
        <EntityAftnAddressesComponent
          displayInfoInput={infoField}
          control={control}
          flightPlanDetails={
            displayInfo.entityName === "Flight Plan"
              ? {
                  setErrorMessage: setErrorMessage,
                  flightPlan: edittedEntity as any as FlightPlan,
                  trigger: trigger,
                }
              : undefined
          }
          flightDetails={
            displayInfo.entityName === "Flight" && (edittedEntity as any as Flight).customRoute
              ? {
                  setErrorMessage: setErrorMessage,
                  flight: edittedEntity as any as Flight,
                  trigger: trigger,
                }
              : undefined
          }
        />
      );
    }else if (infoField.type === "fuelOnBoard"){
      return (
        <EntityFuelOnBoardComponent
          displayInfoInput={infoField}
          control={control}
          edittedFlight={edittedEntity as any as Flight}
        />
      );
    }else if (infoField.type === "flightId"){
      return (
        <EntityFlightIdComponent
          displayInfoInput={infoField}
          control={control}
          edittedFlight={edittedEntity as any as Flight}
        />
      )
    } else if (infoField.type === "pob"){
      return (
        <EntityPobComponent
          displayInfoInput={infoField}
          control={control}
          edittedFlight={edittedEntity as any as Flight}
        />
      )
    } else if (infoField.type === "signature"){
      return (
        <EntitySignatureComponent
          displayInfoInput={infoField}
          control={control}
          entityId={edittedEntity.id}
        />
      )
    } else if (infoField.type === "flightPlanPoint"){
      return (
        <EntityFlightPlanPointPicker
          displayInfoInput={infoField}
          control={control}
          setModalContent={setModalContent}
          setValue={ (displayInfo.entityName === "Flight") ? (setValue as unknown as UseFormSetValue<Flight>) : undefined}
          passengersLegValue={ (displayInfo.entityName === "Flight") ? (edittedEntity as unknown as Flight).passengersLeg : undefined}
        />
      )
    } else if (infoField.type === "customRoute"){
      var flightPlanDeparture: FlightPlanPoint | undefined;
      var flightPlanDestination: FlightPlanPoint | undefined;
      if (displayInfo.entityName === "Flight Plan") {
        flightPlanDeparture = (edittedEntity as unknown as FlightPlan).departure;
        flightPlanDestination = (edittedEntity as unknown as FlightPlan).destination;
      } else if (displayInfo.entityName === "Flight") {
        flightPlanDeparture = (edittedEntity as unknown as Flight).customRoute?.departure;
        flightPlanDestination = (edittedEntity as unknown as Flight).customRoute?.destination;
      } else if (displayInfo.entityName === "ToolsFlight") {
        flightPlanDeparture = (edittedEntity as unknown as ToolsFlight).departure
        flightPlanDestination = (edittedEntity as unknown as ToolsFlight).destination;
      } else {
        throw new Error("Unsupported entity name for EntityCustomRouteComponent");
      }
      
      return (
        <EntityCustomRouteComponent
          displayInfoInput={infoField}
          control={control}
          allowNewCustomPoints={displayInfo.entityName === "Flight Plan"}
          flightPlanDeparture={flightPlanDeparture}
          flightPlanDestination={flightPlanDestination}
          intermediateStops={(displayInfo.entityName === "Flight") ? ((edittedEntity as unknown as Flight).customRoute?.intermediateStops ?? []) : undefined}
          setValue={ (displayInfo.entityName === "Flight") ? (setValue as unknown as UseFormSetValue<Flight>) : undefined}
        />
      )
    } else if (infoField.type === "wb"){
      return (
        <EntityFlightWbComponent
          displayInfoInput={infoField}
          flight={edittedEntity as any as Flight}
          clearWbCalculation={() => (setValue as unknown as UseFormSetValue<Flight>)("wb", { status: "not-calculated", loads: [] })}
          skipWbCalculation={() => (setValue as unknown as UseFormSetValue<Flight>)("wb", { status: "no-airctaft-data", loads: [] })}
          handleSaveFlight={async (flight: Flight) => {
            return new Promise((resolve, reject) => {
              handleSubmit(async (data: any) => {
                dispatchSaveEntity(data).then((savedFlight) => {
                  resolve(savedFlight as unknown as Flight);
                }).catch((err) => {
                  reject(err);
                })
              },(err: any) => {
                reject(err);
              })();
            });
          }}
        />
      );
    } else if (infoField.type === "airfieldSlot"){
      return (
        <EntityFlightAirfieldSlotComponent
          displayInfoInput={infoField}
          control={control}
        />
      )
    }
  }

  const canDiscardChanges = Object.keys(dirtyFields).length > 0 || displayInfo.entityName === "ImportedRoute"

  const showDiscardChangesOrClose = () => {
    if (canDiscardChanges){
      setSideAlertContent(
        <Container>
          <Row>
            <Col>
              <h2 className="text-danger">Discard changes?</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                Are you sure you want to discard your changes? Any unsaved
                changes will be lost.
              </p>
            </Col>
          </Row>
          <Row className="pt-4 justify-content-center gy-2">
            <Col xs={12}>
              <div className="d-grid g-2">
                <Button
                  onClick={handleClose}
                  variant="danger"
                >
                  Discard Changes
                </Button>
              </div>
            </Col>
            <Col xs={12}>
              <div className="d-grid g-2">
                <Button
                  variant="light"
                  onClick={() => setSideAlertContent(null)}
                >
                  Go Back
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      );

    }else{
      handleClose()
    }
  }


  const discardFlightBriefing = async () => {
    setIsDiscardingFlightBriefing(true)

    await dispatch(discardBriefing(edittedEntity as any as Flight) ).unwrap()
    
    window.location.reload() //TODO: Fix problem with status of flight if not reloaded. 
  }

  const editLockedFlightOffCanvasElement = (
    <Offcanvas backdrop="static" show={showDiscardFlightOffCanvas} placement={"end"}>
      <Offcanvas.Body>
        <Container>
          <Row>
            <Col>
              {lockedFlightReason === "fplFile" && <h2 className="text-danger">Edit Locked Flight?</h2>}
              {lockedFlightReason === "briefing" && <h2 className="text-danger">Delete Briefing Pack?</h2>}
              {lockedFlightReason === "scheduled" && <h2 className="text-danger">Edit Scheduled Flight?</h2>}
            </Col>
          </Row>
          <Row>
            <Col>
              {lockedFlightReason === "fplFile" && <p>The FPL plan has already been filed. Please cancel the FPL first.</p>}
              {lockedFlightReason === "briefing" && (
                <p>Flight Briefing Pack has already been generated for this flight. Are you sure you want to delete them?</p>
              )}
              {lockedFlightReason === "scheduled" && <p>The flight has scheduled actions for FPL File and/or Briefing Pack creation. Please cancel the scheduled actions first.</p>}
            </Col>
          </Row>
          <Row className="pt-4 justify-content-center gy-2">
            <Col xs={12}>
              <div className="d-grid g-2">
                {lockedFlightReason === "fplFile" && (
                  <Button onClick={() => navigate("./fpl")} variant="danger">
                    Cancel FPL
                  </Button>
                )}
                {lockedFlightReason === "briefing" && (
                  <Button disabled={isDiscardingFlightBriefing} onClick={discardFlightBriefing} variant="danger">
                    Delete Briefing pack
                  </Button>
                )}
                {lockedFlightReason === "scheduled" && (
                  <Button onClick={() => navigate("./schedule")} variant="danger">
                    Cancel Scheduled Actions
                  </Button>
                )}
              </div>
            </Col>
            <Col xs={12}>
              <div className="d-grid g-2">
                <Button disabled={isDiscardingFlightBriefing} variant="light" onClick={() => setShowDiscardFlightOffCanvas(false)}>
                  Go Back
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </Offcanvas.Body>
    </Offcanvas>
  );

  const bottomButtons = (
    <Col className="bg-white shadow-lg">
      <Row className="h-100 align-items-center justify-content-center">
        {!lockedFlightReason && (
          <DefaultBottomButtons
            canDiscardChanges={canDiscardChanges}
            isSubmitting={isSubmitting}
            showDiscardChangesOrClose={showDiscardChangesOrClose}
            overrideButtonTitle={displayInfo.nextButtonTitle}
          />
        )}
        {lockedFlightReason && (
          <LockedFlightBottomButtons
            handleClose={handleClose}
            requestEditLockedFlight={() => setShowDiscardFlightOffCanvas(true)}
          />
        )}
      </Row>
    </Col>
  );


  useEffect(() => {
    var newLockedFlightReason: "briefing" | "fplFile" | "scheduled" | undefined = undefined
    if (Object.keys(entity).indexOf("briefing") !== -1 ){
      const flight = entity as any as Flight
      if (flight.briefing) {
        if (flight.briefing.status === "scheduled") { 
          newLockedFlightReason = "scheduled";
        } else if (flight.briefing.status !== "not-started" && flight.briefing.status !== "error") {
          newLockedFlightReason = "briefing";
        }
      }
    }
    if (!newLockedFlightReason && Object.keys(entity).indexOf("fplFile") !== -1 ){
      const fplFile: FlightFplFile = (entity as any).fplFile

      if (fplFile.status === "filed" || fplFile.status === "delayed") {
        newLockedFlightReason = "fplFile"
      }else if (fplFile.status === "scheduled"){
        newLockedFlightReason = "scheduled"
      }
    }
    setLockedFlightReason(newLockedFlightReason)
  }, [entity])

  return (
    <>
      <Modal show size="xl" scrollable contentClassName="h-100" fullscreen="lg-down">
        <Modal.Body className={"p-0"}>
          <ToastContainer position="top-center">
            <Toast autohide delay={5000} onClose={() => setErrorMessage(undefined)} bg="danger" show={(errorMessage?.length ?? 0) > 0}>
              <Toast.Body className="text-white">{errorMessage}</Toast.Body>
            </Toast>
          </ToastContainer>
          <Container fluid className="h-sm-100">
            <Row className="h-100">
              <Col xs={12} sm={4} className="h-sm-100 sticky-top ">
                <Row className="justify-content-between align-items-center pt-4 pb-3 pb-sm-5 bg-sm-down-white shadow-sm-down-sm">
                  <Col xs={"auto"}>
                    <h2 className="my-0">
                      {displayInfo.hardcodedTitle
                        ? displayInfo.hardcodedTitle
                        : (edittedEntity.id ? "Edit " : "Add ") +
                          displayInfo.entityName}
                    </h2>
                  </Col>
                  {dispatchDeleteEntity && edittedEntity.id && (
                    <Col xs={"auto"}>
                      <Button
                        variant="white"
                        className="p-0 me-2"
                        disabled={isSubmitting}
                        onClick={() => 
                         setSideAlertContent( 
                         <Container>
                          <Row>
                            <Col>
                              <h2 className="text-danger"> {(displayInfo.entityCanBeDeleted ?? false) ? "Delete" : "Archive"} {displayInfo.entityName}?</h2>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                            {(displayInfo.entityCanBeDeleted ?? false) ? (
                                <p>
                                  <span>Deleted {displayInfo.entityName} cannot be recovered.</span>
                                </p>
                            ) : (
                              <>
                                <p>
                                  <span>Archived data will not be deleted from already created Flights.</span>
                                </p>
                                <p>
                                  <span>Are you sure you want to archive this {displayInfo.entityName}?</span>
                                </p>
                              </>
                              )}
                            </Col>
                          </Row>
                          <Row className="py-4 justify-content-center gy-2">
                            <Col xs={12}>
                              <div className="d-grid g-2">
                                <Button
                                  onClick={() => {setSideAlertContent(null); handleDelete()}}
                                  variant="danger"
                                >
                                  {(displayInfo.entityCanBeDeleted ?? false) ? "DELETE" : "ARCHIVE"} 
                                </Button>
                              </div>
                            </Col>
                            <Col xs={12}>
                              <div className="d-grid g-2">
                                <Button
                                  variant="light"
                                  onClick={() => setSideAlertContent(null)}
                                >
                                  Go Back
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                        )}
                      >
                        {(displayInfo.entityCanBeDeleted ?? false) ? (
                          <>
                            <FontAwesomeIcon icon={faTrash} className="me-1"/>Delete
                          </>
                          ) : (
                          <>
                            <FontAwesomeIcon icon={faBoxArchive} className="me-1"/>Archive
                          </>
                        )}
                        
                      </Button>
                    </Col>
                  )}
                </Row>
                <Row className="d-none d-sm-block">
                  <Col>
                    <ul className="nav nav-pills flex-column">
                      {shouldDisplaySections
                        .map((section) => {
                          return (
                            <li
                              key={section.sectionId}
                              className={
                                activeInfoSection.sectionId === section.sectionId
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                              onClick={() => handleClickActiveSection(section)}
                              style={{ cursor: "pointer" }}
                            >
                              {section.sectionName}
                            </li>
                          );
                        })}
                    </ul>
                  </Col>
                </Row>
              </Col>

              <Col xs={12} sm={8} className="bg-white shadow-lg overflow-hidden h-100">
                <Row
                  id="scrollableFormRow"
                  className="overflow-scroll justify-content-center"
                  style={{ height: "calc(100% - 70px)" }}
                >
                  <Col xs={12} xl={10}>
                    <div className="d-none d-sm-block" style={{ height: "40px" }}></div>

                    <Form onSubmit={handleSubmit(handleSubmitAfterValidation)} id={"entityDetailsForm"}>
                      {shouldDisplaySections
                        .map((section) => {
                          return (
                            <div
                              id={section.sectionId}
                              key={section.sectionId}
                              className="py-2 scrollableFormRowSection"
                            >
                              <p className="h3 py-2">{section.sectionName}</p>
                              {section.inputs.map((i) => {
                                return (
                                  <div className="mb-3" key={i.key as string}>
                                    {getSpecificInfoField(i)}
                                  </div>
                                );
                              })}
                            </div>
                          );
                        })}
                    </Form>
                    <div style={{ height: "75vh" }}></div>
                  </Col>
                </Row>

                <Row className="d-none d-sm-flex" style={{ height: "70px", borderCollapse: "separate" }}>
                  {bottomButtons}
                </Row>

                <Container className="d-sm-none fixed-bottom">
                  <Row style={{ height: "70px", borderCollapse: "separate" }}>{bottomButtons}</Row>
                </Container>

              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
      <Offcanvas
        backdrop="static"
        show={modalContent}
        placement={"end"}
        className="offcanvas-size-fullscreen-xl"
      >
        {showCloseInModalContent && (
          <Button variant="close" className="position-absolute top-0 end-0 p-4" onClick={() => setModalContent(null)}></Button>
        )}
        <Offcanvas.Body className="p-0">{modalContent}</Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        backdrop="static"
        show={sideAlertContent}
        placement={ "end" }
        className="offcanvas-size-md"
      >
        <Offcanvas.Body>{sideAlertContent}</Offcanvas.Body>
      </Offcanvas>
      
      {editLockedFlightOffCanvasElement}

      <Outlet />
    </>
  );
}

export default EntityDetailsPage

interface DefaultBottomButtonsProps {
  isSubmitting: boolean;
  canDiscardChanges: boolean;
  showDiscardChangesOrClose: () => void;
  overrideButtonTitle?: string;
}

function DefaultBottomButtons({isSubmitting, canDiscardChanges, showDiscardChangesOrClose, overrideButtonTitle}: DefaultBottomButtonsProps) {
  return (
    <>
      <Col xs={4}>
        <div className="d-grid g-2">
          <Button
            disabled={isSubmitting}
            onClick={showDiscardChangesOrClose}
            variant="light"
          >
            {canDiscardChanges ? "Discard Changes" : "Close"}
          </Button>
        </div>
      </Col>
      <Col xs={8} lg={4}>
        <div className="d-grid g-2">
          <Button
            disabled={isSubmitting}
            form="entityDetailsForm"
            type="submit"
            variant="dark"
          >
            {overrideButtonTitle ? overrideButtonTitle : "Save"}
          </Button>
        </div>
      </Col>
    </>
  );
}

interface LockedFlightBottomButtonsProps {
  handleClose: () => void;
  requestEditLockedFlight: () => void;
}

function LockedFlightBottomButtons({ handleClose, requestEditLockedFlight}: LockedFlightBottomButtonsProps) {
  return (
    <>
      <Col xs={4}>
        <div className="d-grid g-2">
          <Button
            onClick={requestEditLockedFlight}
            variant="light"
          >
            Edit Flight
          </Button>
        </div>
      </Col>
      <Col xs={8} lg={4}>
        <div className="d-grid g-2">
          <Button
            onClick={handleClose}
            variant="dark"
          >
            Close
          </Button>
        </div>
      </Col>
    </>
  );
}